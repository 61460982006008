import { Select as ChakraSelect, FormLabel } from '@chakra-ui/react'

const Select = (props) => {
  const {
    id,
    label,
    options,
    register,
    validations,
    ...rest
  } = props

  return (
    <>
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <ChakraSelect
        colorScheme="teal"
        borderColor="gray.200"
        variant="outline"
        fontSize="xs"
        focusBorderColor="teal.300"
        {...register(id, validations)}
        {...rest}
      >
        <option value="">Seleccionar</option>
        {options.map(opt => (
          <option key={opt.value} value={opt.value}>{opt.name}</option>
        ))}
      </ChakraSelect>
    </>
  )
}

export default Select
