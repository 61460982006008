import { EditorState } from 'draft-js';
import { onDraftEditorCopy, onDraftEditorCut } from 'draftjs-conductor';
import { useEffect, useRef, useState } from 'react';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import '../../ReportCreation.css';
import { pickerColors, styles } from './styles';
import { changeColorPickerCube, getContentStateFromDataObject, getHtml, getToolbarDisplay, handlePastedText } from './utils';

export default function TextEditor({ blockDataObject, blockKey, setIsUsing }) {
  const [editorState, setEditorState] = useState(() => {
    const contentState = getContentStateFromDataObject(blockKey, blockDataObject)
    if (contentState) return EditorState.createWithContent(contentState);
    return EditorState.createEmpty()
  });
  const [isFocused, setIsFocused] = useState(false)
  const html = useRef('')
  const editorRef = useRef(null)
  const toolbarDisplay = getToolbarDisplay(editorRef)


  const onEditorStateChange = (editorState) => {
    setEditorState(editorState)
  }

  const isElementInViewport = (el) => {
    const rect = el.getBoundingClientRect();
    return ({
      top: rect.top,
      left: rect.left,
      bottom: rect.bottom,
      right: rect.right
    }
    );
  }

  /**
   * Esta funcion maneja el estilo de la toolBar monitoreando la posicion del editor en la ventana
   */
  const handleScroll = () => {
    const position = isElementInViewport(editorRef.current)
    if (editorRef.current && position) {
      if (position.top <= 0) {
        toolbarDisplay.followMe()
      } else {
        toolbarDisplay.home()
      }
      if (position.bottom <= 100) {
        toolbarDisplay.none()
      }
    }
  };

  useEffect(() => {
    if (isFocused) {
      window.addEventListener('scroll', handleScroll);
      handleScroll();
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isFocused]);

  const onBlur = () => {
    setIsUsing(true)
    setIsFocused(false)
    blockDataObject[blockKey].data = getHtml(editorState)
    html.current = getHtml(editorState)
    window.removeEventListener('scroll', handleScroll);
    toolbarDisplay.none()
  }


  useEffect(() => {
    editorRef?.current?.querySelector('.rdw-colorpicker-wrapper').addEventListener('click', changeColorPickerCube(editorRef))
    toolbarDisplay.none()
    return () => {
      editorRef?.current?.querySelector('.rdw-colorpicker-wrapper').removeEventListener('click', changeColorPickerCube(editorRef))
    }
  }, [])

  return (
    <div ref={editorRef} style={{ minHeight: 100 }}>
      <Editor
        toolbar={{
          options: ['inline', 'list', 'link', 'history', 'colorPicker', 'fontSize'],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
          },
          colorPicker: {
            colors: pickerColors
          },
          fontSize: {
            options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96]
          }
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => onBlur()}
        editorStyle={styles.editorStyle}
        wrapperStyle={styles.wrapperStyle}
        toolbarHidden={false}
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
        onCopy={onDraftEditorCopy}
        onCut={onDraftEditorCut}
        handlePastedText={handlePastedText(setEditorState)}
      />

    </div>
  )
}


