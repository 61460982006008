import { LanguageEnum } from "constants/languaje";
import { Flag } from '../../../../components/Flag/Flag';
const { Tooltip } = require("@chakra-ui/react")
const { useReportNavigation } = require("hooks/useReportNavigation")

export function RenderFlags({ reportId, availableLanguages }) {
    const originalLanguage = availableLanguages.split(',')[0]

    function CustomFlag({ language }) {
        const { goToEdition } = useReportNavigation()

        if (!availableLanguages.includes(language)) return null
        const isOriginalLanguage = originalLanguage === language
        return (
            <div>
                <Flag language={language} onClick={() => goToEdition(reportId, language)} isOriginalLanguage={isOriginalLanguage} showTranslationIcon={!isOriginalLanguage} />
            </div>
        )
    }

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', width: '55px' }}>
                <CustomFlag language={LanguageEnum.spanish} />
                <CustomFlag language={LanguageEnum.english} />
            </div>
        </>
    )
}
