import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { Button, Flex, IconButton, useColorModeValue } from "@chakra-ui/react";
import { FIRST_ELLIPSIS_LIMIT, FIRST_PAGE, LAST_ELLIPSIS_LIMIT, TOP_LIMIT_PAGINATION } from "components/Table/config";
import usePagination from "./usePagination";

const Pagination = ({ count, rowsPerPage = 10, onChangePage }) => {
  const buttonColor = useColorModeValue("black", "white")
  const {
    handleChangePage,
    currentPage,
    visiblePages,
    totalPages,
  } = usePagination({
    count,
    rowsPerPage,
    onChangePage
  })

  /* Validations */
  const showFirstEllipsis = currentPage > FIRST_ELLIPSIS_LIMIT && totalPages > TOP_LIMIT_PAGINATION
  const showLastEllipsis = currentPage < totalPages - LAST_ELLIPSIS_LIMIT && totalPages > TOP_LIMIT_PAGINATION
  const paginationAllowed = totalPages > TOP_LIMIT_PAGINATION
  const disableNext = currentPage == totalPages
  const disablePrevious = currentPage == FIRST_PAGE

  return (
    <>
      {paginationAllowed && (
        <IconButton
          bg="transparent"
          isDisabled={disablePrevious}
          onClick={() => handleChangePage(currentPage - 1)}
          color={buttonColor}
          icon={<ArrowBackIcon />}
        />
      )}
      <Flex alignItems="baseline">
        {paginationAllowed && (
          <Button
            onClick={() => handleChangePage(FIRST_PAGE)}
            color={currentPage == FIRST_PAGE
              ? "green.300"
              : buttonColor}
          >
            {FIRST_PAGE}
          </Button>
        )}
        {showFirstEllipsis && "..."}
        {visiblePages.map(val => (
          <Button
            key={val}
            bg="transparent"
            mx="1"
            onClick={() => handleChangePage(val)}
            color={val == currentPage
              ? "green.300"
              : buttonColor}
          >
            {val}
          </Button>
        ))}
        {showLastEllipsis && "..."}
        {paginationAllowed && (
          <Button
            onClick={() => handleChangePage(totalPages)}
            color={totalPages == currentPage
              ? "green.300"
              : buttonColor}
          >
            {totalPages}
          </Button>
        )}
      </Flex>
      {paginationAllowed && (
        <IconButton
          bg="transparent"
          isDisabled={disableNext}
          onClick={() => handleChangePage(currentPage + 1)}
          icon={<ArrowForwardIcon />}
        />
      )}
    </>
  )
}

export default Pagination
