/**
 * @description Top limit for not showing pagination
 * @type {String}
 */
export const TOP_LIMIT_PAGINATION = 5

/**
 * @description First page
 * @type {Number}
 */
export const FIRST_PAGE = 1

/**
 * @description First ellipsis limit when showing pagination
 * @type {Number}
 */
export const FIRST_ELLIPSIS_LIMIT = 3

/**
 * @description Last ellipsis limit when showing pagination
 * @type {Number}
 */
export const LAST_ELLIPSIS_LIMIT = 2

/**
 * @description Returns initial number of pages
 * @type {Number}
 */
export function getVisiblePages(totalPages) {
  if (totalPages <= TOP_LIMIT_PAGINATION) {
    return [...Array(totalPages)].map((_, i) => i + 1)
  }

  return [2, 3, 4]
}

