import MultiSelect from "components/MultipleSelect"
import Input from "../../components/Input"
import Select from "../../components/Select"

const Strategy = (props) => {
  const { type, ...rest } = props

  const componentTypes = {
    INP: () => <Input {...rest} />,
    SEL: () => <Select {...rest} />,
    MUL: () => <MultiSelect {...rest} />
  }

  return componentTypes[type]()
}

export default Strategy
