import { ChakraProvider } from "@chakra-ui/react";
import Context from "context";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import theme from "theme/theme";
import { ErrorBoundary } from "./components/ErrorBoundary/ErrorBoundary";
import Error from "./components/ErrorBoundary/Templates/Error";
import Router from './router';

ReactDOM.render(
    <HashRouter>
        <ChakraProvider theme={theme} resetCss={false}>
            <ErrorBoundary fallbackComponent={<Error homePath="/admin" />}>
                <Context>
                    <Router />
                </Context>
            </ErrorBoundary>
        </ChakraProvider>
    </HashRouter>,
    document.getElementById("root")
);
