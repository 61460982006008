import { CsvPreview } from "./CsvPreview";

export function Preview({ type, src, onError, componentStyles = {}, containerStyles = {}, name }) {
  return (
    <div style={{ ..._containerStyles, ...containerStyles }} onClick={() => window.open(src)}>
      <RenderComponent src={src} onError={onError} type={type} styles={componentStyles} name={name} />
    </div>
  )
}

const _containerStyles = {
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
}

function RenderComponent({ type, src, onError, styles = {}, name }) {
  switch (type) {
    case 'image': case 'chart':
      return <img style={{ ..._componentStyles, ...styles }} src={src} onError={onError} />;
    case 'video':
      return <video src={src} style={{ ..._componentStyles, ...styles }} controls onError={onError} />;
    case 'csv':
      return <CsvPreview style={{ ..._componentStyles, ...styles }} src={src} />;
    case 'audio':
      return <div>{src.originalname}</div>;
    default:
      return <div>Error</div>;
  }
}

const _componentStyles = {
  maxHeight: 200,
  maxWidth: 300,
}
