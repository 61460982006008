import { Text } from "@chakra-ui/react";

export function FormLabelWithError({ label, error }) {
  return (
    <Text as={'b'} mb={1} fontSize={'lg'} style={styles.baseLine}>
      {label}
      <span style={styles.errorMessage}>{error}</span>
    </Text>
  );
}

const styles = {
  baseLine: { alignSelf: 'baseline' },
  errorMessage: { color: 'red', fontSize: 11, fontWeight: 'normal', marginLeft: 5 }
}
