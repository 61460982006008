import { useToast } from "@chakra-ui/react"
import { useRef, useState } from "react"
import UserCategoryService from "services/UserCategoryService"
import { getOrderParam } from "utils/filter"

const useUserCategory = () => {
    const [data, setData] = useState([])
    const whereFilterRef = useRef()
    const orderRef = useRef([])
    const toast = useToast()

    async function getUserCategories(page) {
        try {
            const { list, count } = await UserCategoryService.getList(page, { include: ["reportCategories"], where: whereFilterRef.current, order: orderRef.current })
            setData({
                userCategories: list,
                count
            })
        } catch (err) {
            console.error(err)
        }
    }

    function changeFilter(where) {
        whereFilterRef.current = where;
        getUserCategories()
    }

    function changeOrder(orderArray) {
        orderRef.current = getOrderParam(orderArray)
        getUserCategories()
    }

    async function createUserCategory(data, { reportCategoryIds }) {
        await UserCategoryService.create({ ...data, reportCategoryIds })
        toast({
            title: "Categoría de usuario creada",
            position: "bottom",
            status: "success",
            isClosable: true
        })
        getUserCategories()
    }

    async function updateUserCategory(data, { reportCategoryIds }) {
        const userCategory = { ...data }

        delete userCategory.id
        delete userCategory.createdAt
        delete userCategory.updatedAt
        delete userCategory.reportCategories

        await UserCategoryService.update(data.id, { ...userCategory, reportCategoryIds })
        toast({
            title: "Categoría de usuario actualizada",
            position: "bottom",
            status: "success",
            isClosable: true
        })
        getUserCategories()
    }

    async function deleteUserCategory(id) {
        await UserCategoryService.destroy(id)
        toast({ title: 'Categoría eliminada', status: 'success' })
        await getUserCategories()
    }

    return {
        data,
        getUserCategories,
        createUserCategory,
        updateUserCategory,
        deleteUserCategory,
        changeFilter,
        changeOrder
    }
}

export default useUserCategory
