// Chakra imports
import {
    Button,
    Flex,
    Table,
    Tbody,
    Text,
    Thead,
    Tooltip,
    Tr,
    useColorModeValue
} from "@chakra-ui/react";
// Custom components
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useState } from "react";
import { FaFileUpload } from "react-icons/fa";
import Card from "../../components/Card/Card.js";
import { CsvForm } from "../../components/CsvForm/CsvForm";
import { Filter } from "./components/Filter/Filter";
import { Headers } from "./components/Headers/Headers";
import Pagination from "./components/Pagination";




const TableComponent = ({ title, captions, children, openForm, count, filterConfig, onChangePage, onFilterChange, onCsvImport = null, csvHelpFilename = null, onChangeOrder = (orderArray) => { } }) => {
    const bgStatus = useColorModeValue("gray.400", "gray");
    const textColor = useColorModeValue("gray.700", "white");
    const [openCsvForm, setOpenCsvForm] = useState(false);
    return (
        <Card overflow={'auto'} >
            <CsvForm open={openCsvForm} onClose={() => setOpenCsvForm(false)} onImport={onCsvImport} helpFilename={csvHelpFilename} />
            <CardHeader justifyContent="space-between" p='6px 0px 22px 0px'>
                <Text fontSize='xl' color={textColor} fontWeight='bold'>
                    {title}
                </Text>
                {openForm && (
                    <div>
                        <Tooltip label="Crear">
                            <Button onClick={openForm} bg={bgStatus} color="white">
                                Crear {title.toLowerCase()}
                            </Button>
                        </Tooltip>
                        {onCsvImport ?
                            <Tooltip label="Importar desde CSV">
                                <Button ml={2} onClick={() => setOpenCsvForm(true)} bg={bgStatus} color="white">
                                    <FaFileUpload />
                                </Button>
                            </Tooltip>
                            : null
                        }
                    </div>
                )}
            </CardHeader>
            <Filter onChange={onFilterChange} columns={filterConfig} />
            <CardBody>
                <Table variant='simple' color={textColor}>
                    <Thead>
                        <Tr my='.8rem' pl='0px' color='gray.400'>
                            <Headers headers={captions} orderRestriction="only-one" onChangeOrder={onChangeOrder} />
                        </Tr>
                    </Thead>
                    <Tbody>
                        {children}
                    </Tbody>
                </Table>
            </CardBody>
            {
                count > 10 && (
                    <Flex mt="4" ml="auto">
                        <Pagination count={count} onChangePage={onChangePage} />
                    </Flex>
                )
            }
        </Card >
    );
};

export default TableComponent;
