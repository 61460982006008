import { Input as ChakraInput, FormLabel } from '@chakra-ui/react'

const Input = (props) => {
  const { label, register, id, validations } = props

  return (
    <>
      <FormLabel>{label}</FormLabel>
      <ChakraInput
        colorScheme="teal"
        borderColor="gray.200"
        variant="outline"
        fontSize="xs"
        focusBorderColor="teal.300"
        {...register(id, validations)}
        {...props}
      />
    </>
  )
}

export default Input
