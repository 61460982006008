
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    useToast
} from '@chakra-ui/react'
import React, { useState } from 'react'
import AuthService from 'services/AuthService'

const ChangePassword = () => {
    const [open, setOpen] = useState(false)
    const [credentials, setCredentials] = useState({
        password: '',
        confirmPassword: ''
    })
    const [visibility, setVisibility] = useState({
        password: false,
        confirmPassword: false
    })
    const [error, setError] = useState('')
    const toast = useToast()

    const toggleModal = () => {
        setCredentials({
            password: '',
            confirmPassword: ''
        })
        setError('')
        setOpen(!open)
    }

    const toggleVisibility = (field) => {
        setVisibility((prevVisibility) => ({
            ...prevVisibility,
            [field]: !prevVisibility[field]
        }));
    }

    function handleChange(e) {
        const { name, value } = e.target;

        setCredentials((prevCredentials) => ({
            ...prevCredentials,
            [name]: value,
        }));
    }

    function checkPassword() {
        const { password, confirmPassword } = credentials;

        if (password.length < 8 || confirmPassword.length < 8) {
            setError('Las contraseñas deben tener al menos 8 caracteres');
            return true;
        }
        if (password !== confirmPassword) {
            setError('Las contraseñas no coinciden');
            return true;
        }
        setError('');
        return false;
    }


    const submitChange = async () => {
        if (checkPassword()) return
        try {
            await AuthService.changePassword(credentials.confirmPassword)
            toast({ title: 'La contraseña fue actualizada con exito', status: 'success' })
            setOpen(false)
        } catch (err) {
            toast({ title: 'Se produjo un error', status: 'error' })
        } finally {
        }
    }

    return (
        <>
            {
                open &&
                <Modal size={'lg'} isOpen={true} onClose={toggleModal} >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalCloseButton />
                        <ModalBody>
                            <Flex justifyContent={'space-around'} alignItems={'center'} height={300} padding={10} flexDirection={'column'}>
                                <div style={{ alignSelf: 'center', display: "flex", position: 'relative', width: '50%' }}>
                                    <input
                                        maxLength={16}
                                        minLength={8}
                                        name="password"
                                        placeholder="Contraseña"
                                        id="password"
                                        value={credentials.password}
                                        style={{
                                            color: 'inherit',
                                            backgroundColor: 'rgba(0, 0, 0, 0)',
                                            border: '0.0625rem #000',
                                            borderBottom: '0.0625rem solid black',
                                            paddingLeft: 0,
                                            fontWeight: 300,
                                            outline: 'none'
                                        }}
                                        onChange={handleChange}
                                        type={visibility.password ? 'password' : "text"}
                                    />
                                    <span onClick={() => toggleVisibility('password')} style={{ position: 'absolute', right: 5, bottom: 0, cursor: 'pointer', padding: 5 }}>
                                        {visibility.password ? <ViewOffIcon /> : <ViewIcon />}
                                    </span>
                                </div>
                                <Flex style={{ alignSelf: 'center', display: "flex", position: 'relative', width: '50%' }}>
                                    <input
                                        maxLength={16}
                                        minLength={8}
                                        name="confirmPassword"
                                        placeholder="Confirmar contraseña"
                                        id="password"
                                        value={credentials.confirmPassword}
                                        style={{
                                            color: 'inherit',
                                            backgroundColor: 'rgba(0, 0, 0, 0)',
                                            border: '0.0625rem #000',
                                            borderBottom: '0.0625rem solid black',
                                            paddingLeft: 0,
                                            fontWeight: 300,
                                            outline: 'none'
                                        }}
                                        onChange={handleChange}
                                        onBlur={checkPassword}
                                        type={visibility.confirmPassword ? 'password' : "text"}
                                    />
                                    <span onClick={() => toggleVisibility('confirmPassword')} style={{ position: 'absolute', right: 5, bottom: 0, cursor: 'pointer', padding: 5 }}>
                                        {visibility.confirmPassword ? <ViewOffIcon /> : <ViewIcon />}
                                    </span>
                                </Flex>
                                {error ?
                                    <span style={{ padding: 5, color: 'red', fontSize: 13 }}>{error}</span>
                                    :
                                    null
                                }
                                <Button
                                    bg="teal.300"
                                    w="40%"
                                    p="8px 32px"
                                    mb={5}
                                    _hover="teal.300"
                                    color="white"
                                    onClick={submitChange}
                                >
                                    Confirmar
                                </Button>
                            </Flex>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            }
            <Flex paddingTop={5} justifyContent={'center'}>
                <Button
                    bg="teal.300"
                    w="50%"
                    p="8px 32px"
                    mb={5}
                    _hover="teal.300"
                    color="white"
                    fontSize="xs"
                    onClick={toggleModal}
                >
                    Cambiar contraseña
                </Button>
            </Flex>
        </>
    )
}

export default ChangePassword
