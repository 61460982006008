import { Button, Text } from '@chakra-ui/react'

export const Outlined = (props) => {
  return (
    <Button
      p="8px 32px"
      colorScheme="teal.300"
      borderColor="teal.300"
      color="teal.300"
      variant="outline"
      fontSize="xs"
      {...props}
    />
  )
}

export const Contained = (props) => {
  return (
    <Button
      p="8px 32px"
      bg="teal.300"
      _hover="teal.300"
      color="white"
      fontSize="xs"
      {...props}
    />
  )
}

export const TableButton = ({ label = '', onClick = (event) => { } }) => {
  return (
    <Button
      p="0px"
      bg="transparent"
      variant="no-hover"
      onClick={onClick}
    >
      <Text
        fontSize="md"
        color="gray.400"
        fontWeight="bold"
        cursor="pointer"
      >
        {label}
      </Text>
    </Button>
  )
}

