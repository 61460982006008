import { Select as ChakraSelect, Flex, FormLabel, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

const MultiSelect = (props) => {
  const {
    id,
    label,
    options,
    setError,
    clearErrors,
    setFormValues,
    ...rest
  } = props

  const [values, setValues] = useState(props.values || [options[0]])
  const [currentOptions, setCurrentOptions] = useState(props.values ? options : options.filter(opt => opt.id !== options[0].id))

  const handleValues = (e) => {
    if (!e.target.value) {
      setError(id, { type: 'manual', message: 'Campo requerido' })
    }

    if (e.target.value) {
      clearErrors(id)

      const obj = JSON.parse(e.target.value)
      setCurrentOptions((prevCurrent) => prevCurrent.filter(prevVal => prevVal.id !== obj.id))

      const updatedValues = [...values, { name: obj.name, id: obj.id }]
      setValues(updatedValues)
    }
  }

  const handleRemove = (val) => {
    setCurrentOptions((prevCurrent) => [...prevCurrent, val])

    setValues((prevVals) => {
      const updatedValues = prevVals.filter(prevVal => prevVal.id !== val.id)

      if (updatedValues.length === 0) {
        setError(id, {
          type: 'manual',
          message: 'Campo requerido'
        })
      }

      return updatedValues
    })
  }

  useEffect(() => {
    setFormValues((prevFormValues) => ({ ...prevFormValues, [id]: values.map(val => val.id) }))
  }, [values])

  return (
    <>
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <ChakraSelect
        key={values}
        colorScheme="teal"
        borderColor="gray.200"
        variant="outline"
        fontSize="xs"
        focusBorderColor="teal.300"
        onChange={handleValues}
        {...rest}
      >
        <option value="">Seleccionar</option>
        {currentOptions.map(opt => (
          <option
            key={opt.id}
            value={JSON.stringify(opt)}
          >
            {opt.name}
          </option>
        ))}
      </ChakraSelect>
      <Flex wrap="wrap">
        {values.map(val => (
          <Tag
            key={val.id}
            mt="16px"
            mr="8px"
            variant='outline'
            colorScheme='blue'
          >
            <TagLabel>{val.name}</TagLabel>
            <TagCloseButton onClick={() => handleRemove(val)} />
          </Tag>
        ))}
      </Flex>
    </>
  )
}

export default MultiSelect
