import { useState } from "react"

const requiredValidator = (val) => !(!val || val.length === 0)

const validations = {
    title: {
        condition: {
            type: "required",
            validation: (val) => requiredValidator(val)
        }
    },
    description: {
        condition: {
            type: "required",
            validation: (val) => requiredValidator(val)
        }
    },
    tagIds: {
        condition: {
            type: "required",
            validation: (val) => requiredValidator(val)
        }
    },
    reportCategoryId: {
        condition: {
            type: "required",
            validation: (val) => requiredValidator(val)
        }
    },
    cover: {
        condition: {
            type: "required",
            validation: (val) => {
                if (typeof val === 'string') return !!val
                return !!(val?.src)
            }
        }
    },
    body: {
        condition: {
            type: "required",
            validation: (val) => requiredValidator(val)
        }
    }

}

export function useReportValidation() {
    const [formErrors, setFormErrors] = useState({})

    const removeError = (property) => {
        let errors = { ...formErrors }
        delete errors[property]
        setFormErrors(errors)
    }

    const validateAndSetErrors = (report, all = false) => {
        let errors = { ...formErrors }
        for (let property in all ? validations : report) {
            if (validations[property]?.condition.type === 'required') {
                if (!validations[property].condition.validation(report[property])) {
                    errors[property] = 'Requerido'
                } else {
                    delete errors[property]
                }
            }
        }
        setFormErrors(errors)
        if (Object.keys(errors).length === 0) return true
        return false
    }

    return {
        formErrors,
        removeError,
        validateAndSetErrors
    }
}
