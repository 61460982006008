import moment from "moment";

export const dateFilterHandler = (filter, fieldName, value) => {
  value = value.filter(v => Boolean(v)).map((v) => new Date(v).toISOString())
  value = [...new Set(value)]
  switch (value.length) {
    case 0:
      delete filter.current[fieldName]
      break;
    case 1:
      filter.current[fieldName] = { between: [moment(value[0]).startOf('day').toISOString(), moment(value[0]).endOf('day').toISOString()] }
      break;
    case 2:
      filter.current[fieldName] = { between: value }
      break;
    default:
      break;
  }
}
