import { Button, Flex, Switch, Textarea, useColorModeValue } from "@chakra-ui/react"
import Comment from "components/Comment"
import { AppContext } from "context"
import { useContext, useEffect, useState } from "react"
import { useParams } from 'react-router-dom'
import CommentService from "services/CommentService"
import useResponses from "../../hooks/useResponses"
import './pxq-admin.webflow.css'

const CommentResponses = () => {
  const [response, setResponse] = useState('')
  const [report, setReport] = useState()
  const [userInfo, setUserInfo] = useState()
  const [comments, setComments] = useState([])
  const [isHidden, setIsHidden] = useState(false)
  const { reportId, userId } = useParams()
  const { getReport, getUser, fetchComments } = useResponses()
  const { currentUser } = useContext(AppContext)
  const buttonColor = useColorModeValue('cyan.200', 'cyan.400')

  const fetchData = async () => {
    const report = await getReport(reportId)
    const user = await getUser(userId)
    const comments = await fetchComments(reportId, userId)
    setUserInfo(user)
    setReport(report)
    setComments(comments)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const sendResponse = async () => {
    await CommentService.create({
      destinationId: userInfo?.id,
      reportId: report?.id,
      body: response,
      date: new Date(),
      userId: currentUser?.id,
      hiddenUser: isHidden
    })
    setResponse('')
    fetchData()
  }

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>

      <div className="wrapper">
        <div className="header">
          <div className="text-block">{report?.title}</div>
          <div className="text-block">{`${userInfo?.firstName}  ${userInfo?.lastName}`}</div>
        </div>
        <div className="wrapperconversaion">
          {comments.map((c) => (
            <Comment comment={c} user={userInfo} />
          ))}
        </div>

        <div className="footer">
          <div className="wrappercontesta">
            <Textarea value={response} onChange={(e) => setResponse(e.target.value)} resize={"none"} />
          </div>
          <div className="div-block">
            <div style={{ alignItems: 'center', display: "flex", justifyContent: "space-evenly" }}>
              user
              <Switch value={isHidden} onChange={() => setIsHidden(!isHidden)} colorScheme={"cyan"} />
              pxq
            </div>
            <Button backgroundColor={buttonColor} onClick={() => sendResponse()}>Enviar</Button>
          </div>
        </div>
      </div>
    </Flex>
  )
}

export default CommentResponses
