import {
    Flex,
    Td,
    Text,
    Tr,
    useColorModeValue
} from "@chakra-ui/react";
import { TableButton } from "components/Buttons";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { shortText } from "utils/string";

function CommentTableRow({ row, name, report, body, date, category }) {
    const history = useHistory()
    const textColor = useColorModeValue("gray.700", "white");

    return (
        <Tr>
            <Td>
                <Flex direction="column">
                    <Text textTransform="capitalize" fontSize="md" color={textColor} fontWeight="bold">
                        {name}
                    </Text>
                </Flex>
            </Td>
            <Td>
                <Flex direction="column">
                    <Text textTransform="capitalize" fontSize="md" color={textColor} fontWeight="bold">
                        {shortText(report)}
                    </Text>
                </Flex>
            </Td>
            <Td>
                <Flex direction={'column'}>
                    <Text textTransform="capitalize" fontSize="md" color={textColor} fontWeight="bold">
                        {shortText(body)}
                    </Text>
                </Flex>
            </Td>
            <Td>
                <Flex direction={'column'}>
                    <Text textTransform="capitalize" fontSize="md" color={textColor} fontWeight="bold">
                        {moment(date).format('MM/DD/YYYY')}
                    </Text>
                </Flex>
            </Td>
            <Td>
                <Flex direction={'column'}>
                    <Text textTransform="capitalize" fontSize="md" color={textColor} fontWeight="bold">
                        {category}
                    </Text>
                </Flex>
            </Td>
            <Td>
                <TableButton
                    label="Responder"
                    onClick={() => history.push(`/admin/comments/${row.reportId}/${row.userId}`)}
                />
            </Td>
        </Tr>
    )
}

export default CommentTableRow
