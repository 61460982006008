import { Box } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/react";
import { ProgressBar } from "components/ProgressBar/ProgressBar";
import { createContext, useEffect, useState } from "react";
import { globalErrorHandler, onGlobalError } from "../utils/errorHandlers";

export const AppContext = createContext()

export default ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  useEffect(() => {
    globalErrorHandler(onGlobalError({ toast, setLoading }))
  }, [])
  return (
    <AppContext.Provider value={{
      loading,
      currentUser,
      setCurrentUser,
      setLoading
    }}>
      <Box pointerEvents={loading ? "none" : "all"} opacity={loading ? 0.5 : 1}>
        <ProgressBar loading={loading} />
        {children}
      </Box>
    </AppContext.Provider>
  )
}

