import {
    Badge,
    Box,
    Switch,
    Td,
    Text,
    Tr,
    useColorModeValue,
    useToast
} from "@chakra-ui/react";
import AlertDialogComponent from "components/Dialog";
import { AppContext } from "context";
import { usePromise } from "hooks/usePromise";
import { useContext, useState } from "react";

import ReportCategoryService from "services/ReportCategoryService";
import { TableButton } from "../../components/Buttons";

function SwitchComponent({ id, service, field, status, getter }) {
    const toast = useToast()

    async function onChangePublishState() {
        try {
            await service.updateFields(id, { [field]: !status })
            toast({
                title: "Campo actualizado con éxito",
                status: "success",
                isClosable: true
            })
        } finally {
            getter()
        }
    }

    return (
        <>
            <Box ml="5px">
                <Switch size="sm" onChange={() => onChangePublishState()} isChecked={status} />
            </Box>
        </>
    )
}

function ReportCategoryTableRow({ name, description, row, onEditForm, deleteUserCategory, isDailyPublished, morningFeatured, eveningFeatured, getReportCategories }) {
    const textColor = useColorModeValue("gray.700", "white");
    const bgStatus = useColorModeValue("gray.400", "#1a202c");
    const colorStatus = useColorModeValue("white", "gray.100");
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const { loading } = useContext(AppContext)
    const promiseHandler = usePromise()

    async function handleDeleteDialog(action) {
        if (action === 'accept') {
            try {
                await promiseHandler(() => deleteUserCategory(row.id))
            } finally {
                setOpenDeleteDialog(false)
            }
        }
    }
    return (
        <>
            <AlertDialogComponent isOpen={openDeleteDialog} title="Eliminar la categoría" body="¿Está seguro que desea eliminar la categoría?. La acción es irreversible" loading={loading} onClick={handleDeleteDialog} type="warning" />
            <Tr>
                <Td>
                    <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
                        {name}
                    </Text>
                </Td>
                <Td>
                    <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
                        {description}
                    </Text>
                </Td>
                <Td>
                    <Badge
                        bg={isDailyPublished ? "green.400" : bgStatus}
                        color={!isDailyPublished ? "white" : colorStatus}
                        fontSize="xs"
                        p="5px 10px"
                        borderRadius="8px"
                        textTransform="none"
                        width="fit-content"
                        display="flex"
                    >
                        {isDailyPublished ? "Sí" : "No"}
                        <SwitchComponent getter={getReportCategories} field='isDailyPublished' status={isDailyPublished} id={row.id} service={ReportCategoryService} />
                    </Badge>
                </Td>
                <Td>
                    <Badge
                        bg={morningFeatured ? "green.400" : bgStatus}
                        color={!morningFeatured ? "white" : colorStatus}
                        fontSize="xs"
                        p="5px 10px"
                        borderRadius="8px"
                        textTransform="none"
                        width="fit-content"
                        display="flex"
                    >
                        {morningFeatured ? "Sí" : "No"}
                        <SwitchComponent getter={getReportCategories} field='morningFeatured' status={morningFeatured} id={row.id} service={ReportCategoryService} />
                    </Badge>
                </Td>
                <Td>
                    <Badge
                        bg={eveningFeatured ? "green.400" : bgStatus}
                        color={!eveningFeatured ? "white" : colorStatus}
                        fontSize="xs"
                        p="5px 10px"
                        borderRadius="8px"
                        textTransform="none"
                        width="fit-content"
                        display="flex"
                    >
                        {eveningFeatured ? "Sí" : "No"}
                        <SwitchComponent getter={getReportCategories} field='eveningFeatured' status={eveningFeatured} id={row.id} service={ReportCategoryService} />
                    </Badge>
                </Td>
                <Td>
                    <TableButton
                        label="Editar"
                        onClick={() => onEditForm(row)}
                    />
                </Td>
                <Td>
                    <TableButton
                        label="Eliminar"
                        onClick={() => setOpenDeleteDialog(true)}
                    />
                </Td>
            </Tr>
        </>
    );
}

export default ReportCategoryTableRow;
