import { LanguageEnum } from "constants/languaje";
import { useReportNavigation } from "hooks/useReportNavigation";
import { useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import ReportService from "../../../services/ReportService";

export function useLanguageHandler() {
  const [selectedLanguage, setSelectedLanguage] = useState(language ?? LanguageEnum.spanish)
  const { id, language } = useParams();
  const isCreation = Boolean(useRouteMatch("/admin/reports/create"))
  const reportNavigation = useReportNavigation()

  useEffect(() => {
    if (language) setSelectedLanguage(language)
  }, [language])

  const onChangeLanguage = async (language) => {
    //Si se cambia el lenguaje en la creación solo se cambia el lenguaje.
    if (isCreation) return setSelectedLanguage(language)
    //Si no se está creando se está en una edición
    let reportExist = false
    try {
      reportExist = !!await ReportService.findByLanguage(id, language, { fields: ['id'] })
    } catch (error) {
      //Si no encuentra el reporte en ese idioma entonces no se genera error si no que más adelante se consulta por si se quiere crear una traducción
      if (error.response?.status !== 404) throw error
      console.error(error)
    }
    //Si existe se navega
    if (reportExist) return reportNavigation.goToEdition(id, language)
    //Si no existe se consulta por si se quiere crear una traducción
    const createTranslation = window.confirm(`No existe una traducción en ${language === LanguageEnum.spanish ? 'ESPAÑOL' : 'INGLÉS'} de este informe\n¿Desea crear una?`)
    //Si la respuesta es negativa entonces no se hace nada
    if (!createTranslation) return
    //Se crea una traducción vacía y se navega a ella
    await ReportService.createEmptyTranslation(id, language)
    return reportNavigation.goToEdition(id, language)
  }
  return {
    onChangeLanguage,
    setSelectedLanguage,
    selectedLanguage
  }
}
