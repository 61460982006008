import { DrawerBody, DrawerFooter, FormLabel, Select, useToast } from '@chakra-ui/react'
import { HelpLink } from 'components/HelpLink/HelpLink'
import { useState } from 'react'
import { Contained, Outlined } from '../../components/Buttons'
import Drawer from '../../components/Drawer'
import { FileUploader } from '../../components/FilerUploader/FileUploader'
import { LocalStorageKeys } from '../../constants/localStorage'
import { getObject, setObject } from '../../utils/localStorage'
import { ImportFailureMessage } from './components/ImportResult'
import { getStatus } from './utils'

const DEFAULT_CSV_CONFIG = {
    delimiter: ';',
    headers: true
}

export function CsvForm({ open = false, title = '', helpFilename = null, onImport = async (file, config) => { file }, onClose = async () => { }, onError = null }) {
    const [file, setFile] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [csvConfig, setCsvConfig] = useState(getObject(LocalStorageKeys.CSV_CONFIG) ?? DEFAULT_CSV_CONFIG)
    const [result, setResult] = useState(null)
    const toast = useToast()

    function onChangeCsvConfig(event) {
        let { value, name } = event?.target
        if (name === 'headers') value = value === 'true'
        const config = { ...csvConfig, [name]: value }
        setCsvConfig(config)
        setObject(LocalStorageKeys.CSV_CONFIG, config)
    }

    function onDelete() {
        setFile(null)
    }

    function onCancel() {
        setFile(null)
        setResult(null)
        onClose()
    }

    async function _onImport() {
        try {
            setIsLoading(true)
            setResult(null)
            const res = await onImport(file, csvConfig)
            const status = getStatus(res)
            switch (status) {
                case 'success':
                    toast({ title: 'Archivo importado', status: 'success' })
                    setFile(null)
                    onClose()
                    break;
                case 'partial':
                    toast({ title: 'Archivo importado con errores', status: 'warning' })
                    setResult(res)
                    break;
                case 'fail':
                    toast({ title: 'Archivo no importado', status: 'error' })
                    setResult(res)
                    break;
            }
        } catch (error) {
            console.error(error)
            if (onError) return onError(error)
            throw error
        } finally {
            setIsLoading(false)
        }
    }
    return (
        <>
            <Drawer title={title || 'Importar desde CSV'} isOpen={open} onClose={onClose}  >
                <DrawerBody>
                    <HelpLink filename={helpFilename} />
                    <FileUploader storageType='private' type='csv' previewSrc={file?.originalname} onUpload={(file) => setFile(file)} onDelete={onDelete} />
                    <FormLabel>Separador</FormLabel>
                    <Select name='delimiter' value={csvConfig.delimiter} onChange={onChangeCsvConfig}>
                        <option value={','}>Coma " , "</option>
                        <option value={';'}>Punto y coma " ; "</option>
                    </Select>
                    <ImportFailureMessage result={result} style={{ marginTop: '1rem' }} />
                </DrawerBody>
                <DrawerFooter justifyContent="space-around">
                    <Outlined
                        onClick={onCancel}
                        w="48%">
                        Cancelar
                    </Outlined>
                    <Contained
                        disabled={!file}
                        w="48%"
                        isLoading={isLoading}
                        onClick={_onImport}
                    >
                        Importar
                    </Contained>
                </DrawerFooter>
            </Drawer>
        </>
    )
}
