import { Box, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaFileCsv } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";
import FileService from "../../../services/FileService";
import { getStatus } from "../utils";

export function ImportFailureMessage({ result, style }) {
  if (!result) return null
  const { filename } = result
  const [url, setUrl] = useState('')
  useEffect(() => {
    (async () => {
      if (!filename) return
      const uri = await FileService.getObjectURL(filename, 'private')
      setUrl(uri)
    })()
  }, [result])
  const status = getStatus(result)
  const color = status === 'fail' ? 'red' : 'orange'
  return (
    <div style={style}>
      <IoIosWarning style={{ color, marginBottom: '.2rem' }} />
      <Box style={{ ...styles.box, borderColor: color }}>
        <RenderMessage result={result} />
        <Text style={{ marginTop: '1rem' }}>{`Para ver los detalles consultá el siguiente archivo:`}</Text>
        <a download={filename} href={url} style={styles.fileIconContainer}>
          <FaFileCsv style={{ ...styles.csvIcon, color }} />
        </a>
      </Box>
    </div>
  )
}

const styles = {
  box: {
    borderRadius: '.4rem',
    borderWidth: 1,
    padding: '1rem'
  },
  fileIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '.5rem'
  },
  csvIcon: {
    alignSelf: 'center',
    fontSize: '1.5rem',
    cursor: 'pointer'
  }
}


function RenderMessage({ result }) {
  const status = getStatus(result)
  const { fail, total } = result
  const ComponentMap = {
    fail: () => <><strong>{`No puedo importarse ningún elemento`}</strong></>,
    partial: () => <><strong>{`Fallaron:`}</strong> <span>{`${fail} de ${total} elementos`}</span></>
  }
  const Component = ComponentMap[status]
  return <Component />
}
