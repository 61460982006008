export function fileUploadErrorHandler(toast) {
    return (error, source,) => {
        console.error(error)
        if (source == 'upload') toast({ title: 'Error al subir el archivo. Intente nuevamente', status: 'error' })
    }
}


export const globalErrorHandler = (onError) => {
    window.onerror = (event) => {
        onError(event)
    };
    window.onunhandledrejection = (event) => {
        onError(event)
    };
}

/**
 * Callback para la recepción de error en nuestra aplicación
 * @param {*} param0
 * @returns
 */
export const onGlobalError = ({ toast, setLoading }) => {
    return ({ reason }) => {
        console.error(reason)
        const message = getErrorMessage(reason)
        toast({ title: message, status: 'error', isClosable: true })
        setLoading(false)
    }
}

/**Clase para generar errores con mensajes de usuario. Son mensajes que se mostrarán en pantalla */
export class UserError extends Error {
    constructor(
        /**Mensaje de usuario */
        userMessage = '',
        /**Mensaje opcional para información específica  del error */
        message = '') {
        super(message || userMessage);
        this.userMessage = userMessage
    }
}


function getErrorMessage(reason) {
    const defaultMessage = 'Ha ocurrido un error'
    const message = reason?.userMessage ?? getUserErrorMessageFromResponse(reason?.response) ?? getStatusCodeMessage(reason?.response?.status) ?? defaultMessage
    return message
}

function getUserErrorMessageFromResponse(response) {
    if (!response) return null
    return response.data.error.userMessage
}
function getStatusCodeMessage(statusCode) {
    if (!statusCode) return null
    return errorMessageMapping[statusCode] ?? `Ha ocurrido un error ${statusCode}`
}

const errorMessageMapping = {
    422: 'Error en los campos enviados',
    403: 'No autorizado',
}
