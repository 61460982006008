// Chakra imports
import { Flex } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import UserCategoryService from "services/UserCategoryService";
import Form from "../../components/Form";
import UserTableRow from "../../components/Rows/UserTableRow";
import Table from "../../components/Table";
import useUsers from "../../hooks/useUsers";
import UsersService from "../../services/UsersService";
import { FILTER_CONFIG, HEADERS, ROLES, TITLE, initialUserFormData } from "./config";

function Users() {
    const [open, setOpen] = useState(false)
    const [selectedUser, setSelectedUser] = useState(null)//?
    const { data, getUsers, createUser, updateUser, changeFilter, importFromCsv, changeOrder } = useUsers()
    const userRef = useRef(null)
    const toggleModal = () => setOpen(open => {
        if (open) {
            setSelectedUser(null)
            userRef.current = null
            delete initialUserFormData[4].values
            getInitialData()
        }
        return !open
    })

    const onChangePage = (newPage) => getUsers(newPage)

    const onEditForm = async (userView) => {
        const user = (await UsersService.findById(userView.id, {
            include: ["userCategories"]
        })).data
        setSelectedUser(() => {
            if (user.userCategories) {
                initialUserFormData[4].values = user.userCategories.map(cat => ({
                    name: cat.name,
                    value: cat.id
                }))
                user.userCategoryId = user.userCategories[0].id
            }

            userRef.current = { ...user }

            return user
        })

        toggleModal()
    }

    const getInitialData = async () => {
        try {
            const { data } = await UserCategoryService.find()
            initialUserFormData[4].options = data.map(item => ({
                name: item.name,
                value: item.id
            }))
        } catch (err) {
            console.error(err)
        }
    };

    useEffect(() => {
        getUsers()
        getInitialData()
    }, [])

    return (
        <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
            {open && (
                <Form
                    open={open}
                    toggleModal={toggleModal}
                    create={createUser}
                    update={updateUser}
                    initialData={initialUserFormData}
                    defaultValues={userRef.current}
                />
            )}
            <Table
                title={TITLE}
                count={data.count}
                captions={HEADERS}
                openForm={toggleModal}
                onChangePage={onChangePage}
                onFilterChange={changeFilter}
                filterConfig={FILTER_CONFIG}
                onCsvImport={importFromCsv}
                csvHelpFilename={'Guía de Importación de Usuarios.pdf'}
                onChangeOrder={changeOrder}
            >
                {data?.users?.map((row, idx) =>
                    <UserTableRow
                        key={idx}
                        row={row}
                        name={row.fullName}
                        logo={row.logo}
                        email={row.email}
                        domain={ROLES[row.rol]}
                        companyName={row.companyName}
                        status={row.status}
                        date={new Date(row.createdAt).toLocaleDateString()}
                        onEditForm={onEditForm}
                        getUsers={getUsers}
                    />
                )}
            </Table>
        </Flex>
    );
}

export default Users;
