import {
    Badge,
    Box,
    Switch,
    Td,
    Text,
    Tr,
    useColorModeValue,
    useToast
} from "@chakra-ui/react";
import { AppContext } from "context";
import { useContext, useState } from "react";
import { usePromise } from "../../../hooks/usePromise";
import ReportService from "../../../services/ReportService";
import { TableButton } from "../../Buttons/index";
import AlertDialogComponent from "../../Dialog";
import { RenderFlags } from "./Components/RenderFlags";

function SwitchComponent(props) {
    const toast = useToast()
    const [openDialog, setOpenDialog] = useState(false)
    const [loading, setLoading] = useState(false)

    async function onChangePublishState() {
        try {
            setLoading(true)

            if (props.status) {
                await ReportService.unpublish(props.id)
                toast({
                    title: "La publicación ha sido retirada",
                    position: "bottom",
                    status: "success",
                    isClosable: true
                })
            } else {
                await ReportService.publish(props.id)
                toast({
                    title: "Reporte publicado",
                    position: "bottom",
                    status: "success",
                    isClosable: true
                })
            }
        } finally {
            setOpenDialog(false)
            setLoading(false)
            props.setKey((key) => !key)
            props.getReports()
        }
    }

    return (
        <>
            <Box ml="5px">
                <Switch size="sm" onChange={() => setOpenDialog(true)} isChecked={props.status} />
            </Box>
            <AlertDialogComponent
                isOpen={openDialog}
                onClick={(action) => action === "accept" ? onChangePublishState() : setOpenDialog(false)}
                loading={loading}
                title={props.status ? "Retirar publicación" : "Publicar reporte"}
                body={`¿Está seguro que desea ${props.status ? "retirar la publicación de" : "publicar"} este reporte?`}
            />
        </>
    )
}

function ReportTableRow({ likes, bookmarks, views, title, category, author, status, date, language, id, setKey, getReports }) {
    const { loading } = useContext(AppContext)
    const textColor = useColorModeValue("gray.700", "white");
    const bgStatus = useColorModeValue("gray.400", "#1a202c");
    const colorStatus = useColorModeValue("white", "gray.100");
    const promiseHandler = usePromise()
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const toast = useToast()

    async function deleteRow() {
        await ReportService.destroy(id)
        await getReports()
    }

    async function handleDeleteDialog(action) {
        if (action === 'accept') {
            try {
                await promiseHandler(deleteRow)
                toast({ title: 'Reporte eliminado', status: 'success' })
            } finally {
                setOpenDeleteDialog(false)
            }
        } else {
            setOpenDeleteDialog(false)
        }
    }

    return (
        <>
            <AlertDialogComponent isOpen={openDeleteDialog} title="Eliminar reporte" body="¿Está seguro que desea eliminar el reporte?. La acción es irreversible" loading={loading} onClick={handleDeleteDialog} type="warning" />
            <Tr >
                <Td>
                    <Text fontSize="sm" color={textColor} fontWeight="bold" pb=".5rem">
                        {title}
                    </Text>
                </Td>
                <Td>
                    <Text fontSize="sm" color={textColor} fontWeight="bold" pb=".5rem">
                        {category}
                    </Text>
                </Td>
                <Td>
                    <Text fontSize="sm" color={textColor} fontWeight="bold" pb=".5rem">
                        {author}
                    </Text>
                </Td>
                <Td>
                    <Badge
                        bg={status ? "green.400" : bgStatus}
                        color={!status ? "white" : colorStatus}
                        fontSize="xs"
                        p="5px 10px"
                        borderRadius="8px"
                        textTransform="none"
                        width="fit-content"
                        display="flex"
                    >
                        {status ? "Publicado" : "No publicado"}
                        <SwitchComponent getReports={getReports} status={status} id={id} setKey={setKey} />
                    </Badge>
                </Td>
                <Td>
                    <Text fontSize="sm" color={textColor} fontWeight="bold" pb=".5rem">
                        {date}
                    </Text>
                </Td>
                <Td>
                    <Text fontSize="sm" color={textColor} fontWeight="bold" pb=".5rem">
                        {likes}
                    </Text>
                </Td>
                <Td>
                    <Text fontSize="sm" color={textColor} fontWeight="bold" pb=".5rem">
                        {bookmarks}
                    </Text>
                </Td>
                <Td>
                    <Text fontSize="sm" color={textColor} fontWeight="bold" pb=".5rem">
                        {views}
                    </Text>
                </Td>
                <Td>
                    <RenderFlags reportId={id} availableLanguages={language} />
                </Td>
                <Td>
                    <TableButton
                        label="Eliminar"
                        onClick={() => setOpenDeleteDialog(true)}
                    />
                </Td>
                <Td />
            </Tr>
        </>
    );
}

export default ReportTableRow;



