import { Th } from "@chakra-ui/react";
import { useEffect } from "react";
import { useOrder } from "../../../../hooks/useOrder";
import { Header } from "./Header";
import { convertToHeaderObject, getOrderCriteriaByFieldName } from "./utils";

/**
 * Se encarga de renderizar los encabezados de tabla. Maneja de criterios de orden.
 * @param {object} param0
 * @param {object|string} param0.headers Configuración de encabezados
 * @param {function():void} param0.onChangeOrder Callback ante cambios en el criterio de orden
 * @param {'only-one'|null|undefined} param0.orderRestriction `only-one`: Solo un criterio de orden a la vez. `null`|`undefined`: Sin restricción
 * @returns
 */
export function Headers({ headers, onChangeOrder = () => { }, orderRestriction }) {
    const { orderArray, toggleColumnOrder } = useOrder({ orderRestriction })
    const headersObjects = headers?.map(caption => {
        return convertToHeaderObject(caption)
    });


    useEffect(() => {
        onChangeOrder(orderArray)
    }, [orderArray])

    return (
        <>
            {
                headersObjects?.map((headerObject, idx) => {
                    return (
                        <Th onClick={() => { }} color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
                            <Header header={headerObject} orderCriteria={getOrderCriteriaByFieldName(headerObject.fieldName, orderArray)} onClick={toggleColumnOrder} />
                        </Th>
                    );

                })
            }
        </>
    )

}
