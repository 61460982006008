import { useToast } from "@chakra-ui/react"
import { useRef, useState } from "react"
import TagService from "services/TagService"
import { getOrderParam } from "utils/filter"
import { cleanObject } from "utils/object"

const useTags = () => {
    const [data, setData] = useState([])
    const whereFilterRef = useRef()
    const orderRef = useRef()

    const toast = useToast()

    async function getTags(page) {
        const { list, count } = await TagService.getList(page, { where: whereFilterRef.current, order: orderRef.current })
        setData({
            tags: list,
            count
        })
    }

    function changeFilter(where) {
        whereFilterRef.current = where;
        getTags()
    }

    function changeOrder(orderArray) {
        orderRef.current = getOrderParam(orderArray)
        getTags()
    }

    async function createTags(data) {
        const res = await TagService.create(data)
        if (res.hasError) throw new Error("Error, intente nuevamente")
        toast({
            title: "Tag creado",
            position: "bottom",
            status: "success",
            isClosable: true
        })
        getTags()
    }

    async function updateTags(data) {
        const tag = { ...data }
        delete tag.id

        await TagService.update(data.id, cleanObject(tag))
        toast({
            title: "Tag editado",
            position: "bottom",
            status: "success",
            isClosable: true
        })
        getTags()
    }

    async function deleteTag(id) {
        await TagService.destroy(id)
        toast({
            title: "Tag eliminado correctamente",
            status: "success"
        })
        await getTags()
    }

    return {
        data,
        getTags,
        createTags,
        updateTags,
        deleteTag,
        changeFilter,
        changeOrder
    }
}

export default useTags
