import CRUDService from "./CRUDService";
class ReportService extends CRUDService {
    constructor() {
        super("reports");
    }

    async publish(reportId) {
        await this.patch(`publish/${reportId}`)
    }

    async unpublish(reportId) {
        await this.patch(`unpublish/${reportId}`)
    }

    async bringTags(reportId) {
        return await this.get(`/${reportId}/tags/sorted`)
    }

    async create(data, language) {
        return this.post("/" + language, data);
    }

    async update(id, data, language) {
        return this.put("/" + language + "/" + id, data);
    }


    async findByLanguage(id, language, filter) {
        return this.get(`${language}/${id}`, {
            params: {
                filter
            }
        })
    }

    async createEmptyTranslation(id, language) {
        return this.post(`/create-empy-translation/${language}/${id}`)
    }

    async deleteTranslation(id, language) {
        return this.delete(`/translation/${language}/${id}`)
    }

    async fetchUserCommentsFromReport(reportId, userId) {
        return this.get(`/${reportId}/users/${userId}/comments`, {
            params: {
                filter: {
                    include: ["user"]
                }
            }
        })
    }

}

export default new ReportService();
