import { CloseIcon, SmallAddIcon } from '@chakra-ui/icons';
import { Flex, Tag, TagLabel, TagLeftIcon, Text, useColorMode, useColorModeValue } from '@chakra-ui/react';
import { Autocomplete } from 'chakra-ui-simple-autocomplete';
import { useEffect, useState } from 'react';
import TagService from 'services/TagService';
import { capitalize } from 'utils/string';
import './ReportCreation.css';

const styles = {
    container: { width: "100%", zIndex: 1000 },
    tagStyles: { color: 'inherit', backgroundColor: 'transparent', borderWidth: 0.5, borderColor: 'inherit', borderRadius: 5, width: '100%', height: 35, paddingLeft: 7 }
}

function TagInput({ error, tags, setTags, removeError, handleErrors }) {
    const { colorMode } = useColorMode()
    const [tagOptions, setTagOptions] = useState(null)
    const colorValue = useColorModeValue('#F7FAFC', '#1A202C')

    const bringTags = async () => {
        let temporal = []
        const res = await TagService.find()
        res.data.forEach((tag) => {
            temporal.push({ value: tag.id, label: capitalize(tag.name) })
        })
        setTagOptions(temporal)
    }

    const handleResult = async (option) => {
        if (option.length !== 0) {
            if (typeof option[0].value !== 'number') {
                const res = await TagService.create({
                    name: option[0].label
                })
                option[0].value = res.data.id
            }
        }
        if (option.length === 0) {
            handleErrors({ tagIds: [] })
        }
        setTags(option)
    }

    const handleError = (e) => {
        if (tags.length < 1 && !e.target.value) {
            let tagIds = tags.map((t) => t.value)
            handleErrors({ tagIds })
        }
    }

    useEffect(() => {
        document.documentElement.style.setProperty('--tag-options-color', colorValue)
    }, [colorMode])

    useEffect(() => {
        bringTags()
    }, [])

    return (
        <div style={styles.container}>
            {tagOptions && (
                <Autocomplete
                    onFocus={() => removeError('tagIds')}
                    onBlur={(e) => handleError(e)}
                    options={tagOptions}
                    layerStyle={{ width: "100%" }}
                    result={tags}
                    placeholder='Seleccione los tags'
                    renderCreateIcon={() => {
                        return (
                            <Flex style={{ marginLeft: 5, flexDirection: 'row', alignItems: 'center' }}>
                                <SmallAddIcon />
                                <Text>Agregar esta opción</Text>
                            </Flex>
                        )
                    }}
                    setResult={(option) => handleResult(option)}
                    renderBadge={(option) =>
                        <Tag bg={'green.400'} style={{ margin: 5 }}>
                            <TagLeftIcon boxSize={'10px'} as={CloseIcon} />
                            <TagLabel style={{ color: 'white' }}>
                                {capitalize(option.label)}
                            </TagLabel>
                        </Tag>}
                    style={{ ...styles.tagStyles, borderColor: error ? 'red' : 'inherit' }}
                />
            )}
        </div>
    )
}

export default TagInput
