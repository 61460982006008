import { matchPath } from "react-router-dom"
import routes from "routes"

const useBreadcrumb = () => {
  const breadcrumb = []

  for (let route of routes) {
    const match = matchPath(window.location.pathname, { path: route.layout + route.path })

    if (match) {
      breadcrumb.push({ ...match, ...route })
    }
  }

  return breadcrumb
}

export default useBreadcrumb
