// Chakra imports
import { Button, Flex, useColorModeValue } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import ReportTableRow from "../../components/Rows/ReportTableRow/ReportTableRow";
import Table from "../../components/Table";
import { useReportNavigation } from "../../hooks/useReportNavigation";
import useReports from "../../hooks/useReports";
import { FILTER_CONFIG, HEADERS, TITLE } from "./config";

function Reports() {
    const { data, getReports, changeFilter, changeOrder } = useReports()

    const { goToCreation } = useReportNavigation()

    const [keyList, setKeyList] = useState(false)

    const history = useHistory()

    const onChangePage = (newPage) => getReports(newPage)

    const bgStatus = useColorModeValue("gray.400", "gray");

    useEffect(() => {
        getReports()
    }, [])

    return (
        <Flex direction='column' pt={{ base: "120px", md: "75px" }} >
            <Table
                title={TITLE}
                count={data.count}
                captions={HEADERS}
                onChangePage={onChangePage}
                key={keyList}
                filterConfig={FILTER_CONFIG}
                onFilterChange={changeFilter}
                onChangeOrder={changeOrder}
            >
                <Button style={{ position: 'absolute', right: 30, top: 40 }} bg={bgStatus} onClick={() => goToCreation()} color={'white'}>Crear reporte</Button>
                {data?.reports?.map((row, idx) =>
                    <ReportTableRow
                        setKey={setKeyList}
                        getReports={getReports}
                        key={idx}
                        row={row}
                        title={row.title}
                        author={row.createdByFullName ? row.createdByFullName : 'Usuario eliminado'}
                        status={row.published}
                        date={moment(row.createdAt).format("DD/MM/YYYY")}
                        id={row.id}
                        likes={row.likes}
                        bookmarks={row.bookmarks}
                        views={row.views}
                        category={row.reportCategoryName}
                        language={row.availableLanguages}
                    />
                )}
            </Table>
        </Flex>
    );
}

export default Reports;
