import { defaultOptionsGetter } from "../../components/Table/components/Filter/OptionGetters/optionGetter"

export const HEADERS = [
    { label: "Nombre", fieldName: 'title', sortable: true },
    { label: "Categoría", fieldName: 'reportCategoryName', sortable: true },
    { label: "Autor", fieldName: 'createdByFullName', sortable: true },
    { label: "Estado", fieldName: 'published', sortable: true },
    { label: "Alta", fieldName: 'createdAt', sortable: true },
    { label: "Me Gusta", fieldName: 'likes', sortable: true },
    { label: "Guardados", fieldName: 'bookmarks', sortable: true },
    { label: "Vistos", fieldName: 'views', sortable: true },
    "editar",
    ""]

export const TITLE = "Reportes"

export const FILTER_CONFIG = [
    { label: 'Nombre', type: 'string', visible: true, fieldName: 'title', },
    {
        label: 'Categoría', type: 'select', visible: true, fieldName: 'reportCategoryId',
        optionsGetter: defaultOptionsGetter('report-categories', 'name')
    },
    {
        label: 'Autor', type: 'string', visible: true, fieldName: 'createdByFullName',
    },
    {
        label: 'Publicado', type: 'select', visible: true, fieldName: 'published',
        options: [{
            value: true,
            label: 'Publicado'
        },
        {
            value: false,
            label: 'No Publicado'
        }
        ]
    },
    { label: 'Alta', type: 'date', visible: true, fieldName: 'createdAt' },
]
