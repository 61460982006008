// import { ReportService } from '../services/ReportService'
// import { UsersService } from '../services/UsersService'

import UsersService from "services/UsersService"
import ReportService from "../services/ReportService"

const useResponses = () => {
  async function getReport(id) {
    const res = await ReportService.findById(id)

    return res.data
  }

  async function getUser(id) {
    const res = await UsersService.findById(id)

    return res.data
  }

  async function fetchComments(reportId, userId) {
    const res = await ReportService.fetchUserCommentsFromReport(reportId, userId)

    return res.data
  }

  return {
    getReport,
    getUser,
    fetchComments
  }

}

export default useResponses
