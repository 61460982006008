import API_URL from "utils/config";
import HTTPService from "./HTTPService";

class CRUDService extends HTTPService {
    nombreRecurso = "";

    // recibimos en el constructor el nombre de un recurso
    // representa un modelo en la api
    constructor(recurso = "") {
        super()
        this.nombreRecurso = recurso;
        this.http.defaults.baseURL = API_URL + "/" + this.nombreRecurso;
    }


    async getList(page, filterParams = {}) {
        if (!page) page = 1
        const limit = 10
        const offset = limit * (page - 1)
        const resource = await this.get("", {
            params: {
                filter: {
                    limit,
                    offset,
                    ...filterParams,
                    order: [...(filterParams.order ?? []), 'id DESC'],
                }
            },
            //Se modificó la serialización para poder enviar filtros null
            paramsSerializer: (params) => { if (params?.filter) return `filter=${encodeURIComponent(JSON.stringify(params.filter))}` }
        })

        const count = await this.get("/count", {
            params: {
                where: filterParams?.where
            },
            //Se modificó la serialización para poder enviar filtros null
            paramsSerializer: (params) => { if (params?.where) return `where=${encodeURIComponent(JSON.stringify(params.where))}` }
        })

        return {
            count: count.data.count,
            list: resource.data
        }
    }

    async find(filter = {}) {
        return await this.get("", {
            params: {
                filter
            }
        });
    }

    async count(where = {}) {
        return await this.get("/count", {
            params: {
                where
            }
        });
    }

    async findById(id, filter = {}) {
        return await this.get(`/${id}`, {
            params: {
                filter
            }
        })
    }

    async create(data) {
        return await this.post("", data);
    }

    async save(data) {
        return await this.put("/save", data);
    }

    async uploadFiles(file, id, propertyName = "files") {
        const formData = new FormData();
        formData.append("file", file);
        const response = await this.post("/" + id + "/" + propertyName, formData);
        return response;
    }

    async update(id, data) {
        return await this.put("/" + id, data);
    }

    async updateFields(id, data) {
        return await this.patch("/" + id, data);
    }

    async destroy(id) {
        return await this.delete("/" + id);
    }

    async findLinked(modelId, targetName, filter) {
        return await this.get("/" + modelId + "/" + targetName, {
            params: {
                filter
            }
        });
    }

    async link(modelId, targetName, targetId) {
        return await this.patch("/" + modelId + "/" + targetName + "/link/" + targetId);
    }

    async unlink(modelId, targetName, targetId) {
        return await this.delete("/" + modelId + "/" + targetName + "/unlink/" + targetId);
    }
};

export default CRUDService;
