import { AddIcon, ArrowDownIcon, ArrowUpIcon } from "@chakra-ui/icons";
import { Button, Fade, Icon, Tooltip } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Actions from "./Actions";
import './ReportCreation.css';

const styles = {
  container: { flexDirection: 'row', display: 'flex', width: '100%', backgroundColor: 'white', minHeight: 150 },
  icon: { margin: 10, cursor: "pointer", color: 'rgb(55, 211, 133)', borderRadius: 30 },
  addIconContainer: { position: 'relative', flexDirection: 'row', display: 'flex', borderRadius: 30, width: 40, alignItems: 'center', justifyContent: 'center' },
  hiddenActions: { position: 'absolute', right: 70, top: '20%', zIndex: 1000 }
}

export default function BlockContainer({ reference, id, Block, blockDataObject, index, handleActions }) {
  const [showing, setShowing] = useState(true);
  const [showingActions, setShowingActions] = useState(false);
  const [isUsing, setIsUsing] = useState(false)
  const [classe, setClasse] = useState('blockContainerInitial')

  useEffect(() => {
    setClasse('blockContainer')
  }, [])

  useEffect(() => {
    //Dejar undefined porque la key puede ser 0 y es valido.
    if (Block.key !== undefined) {
      if (!blockDataObject[Block.key]) {
        blockDataObject[Block.key] = { index, type: Block.type }
      } else {
        blockDataObject[Block.key].index = index
      }
    }
  }, [index])

  const toggle = () => {
    setShowing(!showing)
  }

  const toggleActions = () => {
    setShowingActions(!showingActions)
  }

  return (
    <div onMouseLeave={() => setShowingActions(false)} id={id} ref={(ref) => reference[index] = ref} className={classe} style={{ ...styles.container, backgroundColor: 'inherit' }}>
      <div onClick={() => toggle()} className="textContainer">
        <Block.Component blockDataObject={blockDataObject} blockKey={Block.key} setIsUsing={(value) => setIsUsing(value)} />
      </div>
      <div className="directionButton" >
        <Tooltip label='Mover bloque arriba'>
          <ArrowUpIcon onClick={() => handleActions('moveUp', index)} style={styles.icon} boxSize={6} />
        </Tooltip>
        <div style={styles.addIconContainer}>
          <Tooltip label='Mas opciones'>
            <Button style={styles.icon} onClick={() => toggleActions()}>
              <Icon boxSize={5} as={AddIcon} />
            </Button>
          </Tooltip>
          <Fade in={showingActions}>
            <div style={styles.hiddenActions}>
              {
                showingActions ?
                  <Actions onClick={(action) => handleActions(action, index)} direction={'row'} />
                  :
                  null
              }
            </div>
          </Fade>
        </div>
        <Tooltip label='Mover bloque abajo'>
          <ArrowDownIcon onClick={() => handleActions('moveDown', index)} style={styles.icon} boxSize={6} />
        </Tooltip>
      </div>
    </div>
  )
}
