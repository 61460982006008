import { Input } from "@chakra-ui/react";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";

export function DateComponent({ column, onChange, ...rest }) {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  return (
    <ReactDatePicker
      id={column.fieldName}
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onChange={(update) => {
        setDateRange(update);
        onChange({ target: { value: update, id: column.fieldName } })
      }}
      isClearable={true}
      allowSameDay={false}
      customInput={<Input {...rest} />}
      withPortal
    />
  )
}
