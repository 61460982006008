'use client'
import { CloseIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function Error({ homePath = '/' }) {
  const history = useHistory();

  return (
    <Box textAlign="center" py={10} px={6}>
      <Box display="inline-block">
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bg={'red.500'}
          rounded={'50px'}
          w={'55px'}
          h={'55px'}
          textAlign="center">
          <CloseIcon boxSize={'20px'} color={'white'} />
        </Flex>
      </Box>
      <Heading as="h2" size="xl" mt={6} mb={2}>
        ¡Ups! Algo salió mal.
      </Heading>
      <Text color={'gray.500'}>
        Lamentamos los inconvenientes. Parece que ha ocurrido un error inesperado.
        Te recomendamos regresar a la página principal y volver a intentarlo. Si el problema persiste,
        no dudes en contactar a nuestro equipo de soporte.
      </Text>
      <Button margin={20} onClick={() => {
        history.push(homePath)
        window.location.reload()
      }}>
        Volver al inicio
      </Button>
    </Box>
  )
}
