import { Text, useColorModeValue } from "@chakra-ui/react";
import "react-datepicker/dist/react-datepicker.css";
import { capitalize } from "utils/string";
import { DateComponent } from "./FilterTypes/Date/DateComponent";
import { NumberComponent } from "./FilterTypes/Number/NumberComponent";
import { SelectComponent } from "./FilterTypes/Select/SelectComponent";
import { TextComponent } from "./FilterTypes/Text/TextComponent";

/**
  * Permite el renderizado de los componentes según el tipo de filtro
  * @param {{
* column:{
  * label: string,
  * type: 'string' | 'number' | 'date',
  * fieldName: 'string'
  * visible: boolean,
  * customFilterHandler:(filter:Filter)=>void
  * }}} param0
  * @returns
  */
export function FilterStrategy({ column, onChange, ...rest }) {
  const textColor = useColorModeValue("gray.400", "white");
  const Component = ComponentMap[column.type]
  if (!column.visible || !Component) return null
  return (
    <div style={{ display: 'flex', flexDirection: 'column', margin: 5 }}>
      <Text fontSize='xs' color={textColor} fontWeight='bold'>{capitalize(column?.label?.toLowerCase())}</Text>
      <Component column={column} onChange={onChange} {...rest} />
    </div>
  )
}


const ComponentMap = {
  string: TextComponent,
  number: NumberComponent,
  date: DateComponent,
  select: SelectComponent
}




