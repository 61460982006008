export function TranslationIcon({ show, size = 1, style = {} }) {
  if (!show) return null
  return (
    <span style={{
      position: 'absolute',
      bottom: `${.2 * size}rem`,
      right: `${-.4 * size}rem`,
      fontSize: `${.8 * size}rem`,
      ...style
    }}>🈯</span>
  )
}
