import { Select } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

export function SelectComponent({ column, onChange, ...rest }) {
    if (!column?.options?.length && !column?.optionsGetter) return null
    const [options, setOptions] = useState([])
    async function loadOptions() {
        setOptions(column.options || await column.optionsGetter())
    }
    useEffect(() => {
        loadOptions()
    }, [])
    return (
        <Select
            id={column.fieldName}
            placeholder='Todos'
            onChange={onChange}
            {...rest}
        >
            {options.map(option =>
                <option value={option.value}>{option.label}</option>
            )}
        </Select>
    )
}
