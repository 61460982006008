import { Progress } from "@chakra-ui/react";

export function ProgressBar({ loading }) {
  return (
    <>
      {
        loading ?
          <Progress
            value={100}
            isIndeterminate
            size="xs"
            colorScheme="green"
            position={'fixed'}
            width={'100%'}
          />
          : null
      }
    </>
  )
}
