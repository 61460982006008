// Chakra imports
import { Flex } from "@chakra-ui/react";
import Form from "components/Form";
import { useEffect, useState } from "react";
import ReportCategoryService from "services/ReportCategoryService";
import UserCategoryTableRow from "../../components/Rows/UserCategoryTableRow";
import Table from "../../components/Table";
import useUserCategory from "../../hooks/useUserCategory";
import { FILTER_CONFIG, HEADERS, TITLE, initialUserCategoryFormData } from "./config";

function UserCategory() {
    const [open, setOpen] = useState(false)
    const [selectedUserCategory, setSelectedUserCategory] = useState(null)
    const { data, getUserCategories, createUserCategory, updateUserCategory, deleteUserCategory, changeFilter, changeOrder } = useUserCategory()
    const toggleModal = () => setOpen(open => {
        if (open) {
            setSelectedUserCategory(null)
            delete initialUserCategoryFormData[2].values
            getInitialData()
        }
        return !open
    })

    const onChangePage = (newPage) => getUserCategories(newPage)

    const onEditForm = (userCategory) => {
        setSelectedUserCategory(() => {
            initialUserCategoryFormData[2].values = userCategory?.reportCategories?.map(cat => ({
                name: cat.name,
                id: cat.id
            }))

            initialUserCategoryFormData[2].options = initialUserCategoryFormData[2].options.filter(opt => {
                return !initialUserCategoryFormData[2].values?.some(value => opt.id === value.id)
            })

            return userCategory
        })

        toggleModal()
    }

    const getInitialData = async () => {
        try {
            const { data } = await ReportCategoryService.find()
            initialUserCategoryFormData[2].options = data.map(item => ({
                name: item.name,
                id: item.id
            }))
        } catch (err) {
            console.error(err)
        }
    };

    useEffect(() => {
        getUserCategories()
        getInitialData()
    }, [])

    return (
        <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
            {open && (
                <Form
                    open={open}
                    toggleModal={toggleModal}
                    create={createUserCategory}
                    update={updateUserCategory}
                    defaultValues={selectedUserCategory}
                    initialData={initialUserCategoryFormData}
                />
            )}
            <Table
                title={TITLE}
                count={data.count}
                captions={HEADERS}
                openForm={toggleModal}
                onChangePage={onChangePage}
                filterConfig={FILTER_CONFIG}
                onFilterChange={changeFilter}
                onChangeOrder={changeOrder}
            >
                {data?.userCategories?.map((row) =>
                    <UserCategoryTableRow
                        row={row}
                        key={row.name}
                        name={row.name}
                        description={row.description}
                        reportCategories={row.reportCategories}
                        onEditForm={onEditForm}
                        deleteUserCategory={deleteUserCategory}
                    />
                )}
            </Table>
        </Flex>
    );
}

export default UserCategory;
