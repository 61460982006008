// Chakra Imports
import {
  Drawer as ChakraDrawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text
} from "@chakra-ui/react";
import { Separator } from "components/Separator/Separator";
import PropTypes from "prop-types";

export default function Drawer(props) {
  const { isOpen, title, children } = props;

  return (
    <>
      <ChakraDrawer
        isOpen={isOpen}
        onClose={props.onClose}
        placement="right"
        blockScrollOnMount={false}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader pt="24px" px="24px">
            <DrawerCloseButton />
            <Text fontSize="xl" mb="24px" fontWeight="bold" mt="16px">
              {title}
            </Text>
            <Separator />
          </DrawerHeader>
          {children}
        </DrawerContent>
      </ChakraDrawer>
    </>
  );
}
Drawer.propTypes = {
  secondary: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};
