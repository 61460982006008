import {
    Avatar,
    Badge,
    Flex,
    Td,
    Text,
    Tr,
    useColorModeValue,
    useToast
} from "@chakra-ui/react";
import AlertDialog from "components/Dialog";
import useUsers from "hooks/useUsers";
import { useState } from "react";
import { TableButton } from "../../components/Buttons";

function UserTableRow({ logo, name, email, domain, status, date, row, companyName, onEditForm, getUsers }) {
    const [openDialog, setOpenDialog] = useState(false)
    const [loading, setLoading] = useState(false)
    const { deleteUser } = useUsers()
    const toast = useToast()

    const textColor = useColorModeValue("gray.700", "white");
    const bgStatus = useColorModeValue("gray.400", "#1a202c");
    const colorStatus = useColorModeValue("white", "gray.100");

    async function onDeleteUser(action) {
        setLoading(true)

        if (action === "accept") {
            await deleteUser(row.id)
            await getUsers()
        }

        setOpenDialog(false)
        setLoading(false)
    }

    return (
        <Tr>
            <Td minWidth={{ sm: "250px" }} pl="0px">
                <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                    <Avatar src={logo} w="50px" borderRadius="12px" me="18px" />
                    <Flex direction="column">
                        <Text
                            fontSize="md"
                            textTransform="capitalize"
                            color={textColor}
                            fontWeight="bold"
                            minWidth="100%"
                        >
                            {name}
                        </Text>
                        <Text fontSize="sm" color="gray.400" fontWeight="normal">
                            {email}
                        </Text>
                    </Flex>
                </Flex>
            </Td>

            <Td>
                <Flex direction="column">
                    <Text textTransform="capitalize" fontSize="md" color={textColor} fontWeight="bold">
                        {domain}
                    </Text>
                </Flex>
            </Td>
            <Td>
                <Badge
                    textTransform="capitalize"
                    bg={status === "active" ? "green.400" : bgStatus}
                    color={status === "inactive" ? "white" : colorStatus}
                    fontSize="16px"
                    p="3px 10px"
                    borderRadius="8px"
                >
                    {status === "active" ? "Activo" : "Inactivo"}
                </Badge>
            </Td>
            <Td>
                <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
                    {date}
                </Text>
            </Td>
            <Td>
                <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
                    {companyName}
                </Text>
            </Td>
            <Td></Td>
            <Td>
                <TableButton
                    label="Editar"
                    onClick={() => onEditForm(row)}
                />
            </Td>
            <Td>
                <TableButton
                    label="Eliminar"
                    onClick={() => setOpenDialog(true)}
                />
            </Td>
            <AlertDialog
                isOpen={openDialog}
                onClick={onDeleteUser}
                title="Eliminar usuario"
                body="¿Estás seguro de eliminar este usuario? Esta acción es irreversible"
                type='warning'
            />
        </Tr>
    );
}

export default UserTableRow;
