import { HelpIcon } from "components/Icons/Icons"
import { useEffect, useState } from "react"
import FileService from "../../services/FileService"

export function HelpLink({ filename }) {
    if (!filename) return null
    const [url, setUrl] = useState('')
    async function getUrl() {
        const _url = await FileService.getObjectURL(filename)
        setUrl(_url)
    }
    useEffect(() => { getUrl() }, [])
    return (
        <div style={{ display: 'flex', justifyContent: 'end' }}>
            <a href={url} target="_blank" ><HelpIcon color={"green.400"} /></a>
        </div>
    )
}
