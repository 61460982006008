import { Flex } from "@chakra-ui/react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { convertToHeaderObject } from "./utils";

/**
 *
 * @param {*} param0
 * @returns
 */
export function Header({ header, orderCriteria = null, onClick = (headerObject) => { }, }) {
    const _headerObject = convertToHeaderObject(header)
    return (
        <>
            {_headerObject?.sortable ?
                <Flex cursor={'pointer'} onClick={() => onClick(_headerObject)}>
                    <div>{_headerObject?.label}</div>
                    <ResolveArrow order={orderCriteria} />
                </Flex>
                : <div>{_headerObject?.label}</div>
            }
        </>
    )
}


function ResolveArrow({ order }) {
    switch (order) {
        case 'DESC':
            return <MdKeyboardArrowDown />
        case 'ASC':
            return <MdKeyboardArrowUp />
        default:
            return null
    }
}
