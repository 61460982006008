import {
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalOverlay
} from '@chakra-ui/react'

export function Dialog({ onClose, children }) {

    return (
        <>
            <Modal size={'full'} isOpen={true} onClose={onClose} >
                <ModalOverlay />
                <ModalContent>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <ModalCloseButton style={{ backgroundColor: 'red', position: 'absolute', right: 100, top: 20 }} />
                    </div>
                    {children}
                </ModalContent>
            </Modal>
        </>
    )
}
