import CRUDService from "./CRUDService";

class FileService extends CRUDService {
  constructor() {
    super("files");
  }

  /**
   * Sube un archivo. Devuelve el valor a asignar en el campo.
   * @param {File} file
   * @param {'public'|'private'} storageType Tipo de almacenamiento
   * @returns
   */
  async uploadFile(file, storageType = 'public') {
    const formData = new FormData();
    formData.append('file', file);
    const res = await this.post("", formData, { params: { storageType } });
    res.data = res.data[0]
    return res;
  }

  /**
   * Elimina archivo por nombre
   * @param {string} filename
   * @param {'public'|'private'} storageType
   * @returns
   */
  async deleteFile(filename, storageType = 'public') {
    return this.delete('/' + filename, { params: { storageType } })
  }

  /**
   * Devuelve un objeto de URL de un archivo de servidor
   * @param {string} filename
   * @param {'public'|'private'} storageType
   * @returns
   */
  async getObjectURL(filename, storageType = 'public') {
    const res = await this.get('/' + filename, { params: { storageType }, responseType: 'blob' })
    return window.URL.createObjectURL(res.data)
  }

}

export default new FileService();
