export const initialTagFormData = [
    {
        id: "name",
        type: "INP",
        placeholder: "",
        label: "Nombre",
        validations: {
            required: "Campo requerido"
        }
    },
    {
        id: "name_en",
        type: "INP",
        placeholder: '',
        label: "Nombre en ingles",
        validations: {
            required: "Campo requerido"
        }
    }
]

export const HEADERS = [
    { label: "Nombre", fieldName: 'name', sortable: true },
    { label: "Nombre en inglés", fieldName: 'name_en', sortable: true }
]

export const TITLE = "Tags"

export const FILTER_CONFIG = [
    { label: 'Nombre', type: 'string', visible: true, fieldName: 'name' },
]
