import { Box, Select } from "@chakra-ui/react";

export function CategorySelect({ categories, value, onChange, error, onBlur, onFocus }) {
  return (
    <Box style={styles.container}>
      <Select
        onChange={onChange}
        placeholder="Seleccione la categoria"
        style={{ borderColor: error ? 'red' : 'inherit' }}
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
      >
        {categories.map((c) => (
          <option key={c.id} value={c.id}>{c.name}</option>
        ))}
      </Select>
    </Box>
  );
}

const styles = {
  container: { width: '50%', alignSelf: 'baseline' },
}
