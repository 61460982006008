import { useToast } from "@chakra-ui/react"
import { AppContext } from "context"
import { useContext } from "react"
import { useHistory } from "react-router-dom"
import AuthService from "services/AuthService"
import { clearTokens } from "utils/localStorage"
import { LocalStorageKeys } from "../constants/localStorage"
import { UserError } from "../utils/errorHandlers"

const useAuth = () => {
  const toast = useToast()
  const { setCurrentUser, setLoading } = useContext(AppContext)
  const history = useHistory()

  async function login(data) {
    try {
      setLoading(true)

      //get token
      const res = await AuthService.login(data)

      //set localStorage with both token
      if (res.data.accessToken) {
        localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, res.data.accessToken)
        localStorage.setItem(LocalStorageKeys.REFRESH_TOKEN, res.data.refreshToken)

        //set user
        const user = await AuthService.findUser()
        //Se detiene el logeo de clientes
        if (user.data.roles[0] === 'customer') {
          clearTokens()
          throw new UserError('No autorizado');
        }
        setCurrentUser(user.data)

        //redirect to admin
        history.push("/admin/users")
      }
    } finally {
      setLoading(false)
    }
  }

  async function logout() {
    try {
      setLoading(true)

      clearTokens()
      setCurrentUser(null)
    } finally {
      setLoading(false)
    }
  }

  async function refreshUser() {
    try {
      setLoading(true)

      //if not refresh token, redirect to login
      if (!localStorage.getItem(LocalStorageKeys.REFRESH_TOKEN)) {
        return history.replace("/admin/auth/signin")
      }

      //if refresh token, set user again and redirect
      const user = await AuthService.findUser()
      setCurrentUser(user.data)
    } catch (err) {
      //if err redirect to login
      history.push("/admin/auth/signin")
    } finally {
      setLoading(false)
    }
  }

  return {
    login,
    logout,
    refreshUser
  }
}

export default useAuth
