import {
  Td,
  Text,
  Tr,
  useColorModeValue
} from "@chakra-ui/react";
import AlertDialogComponent from "components/Dialog";
import { AppContext } from "context";
import { usePromise } from "hooks/usePromise";
import { useContext, useState } from "react";
import { TableButton } from "../../components/Buttons";

function UserCategoryTableRow({ name, description, reportCategories, row, onEditForm, deleteUserCategory }) {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const { loading } = useContext(AppContext)
  const textColor = useColorModeValue("gray.700", "white");
  const promiseHandler = usePromise()

  async function handleDeleteDialog(action) {
    if (action === 'accept') {
      try {
        await promiseHandler(() => deleteUserCategory(row.id))
      } finally {
        setOpenDeleteDialog(false)
      }
    }
  }
  return (
    <>
      <AlertDialogComponent isOpen={openDeleteDialog} title="Eliminar la categoría" body="¿Está seguro que desea eliminar la categoría?. La acción es irreversible" loading={loading} onClick={handleDeleteDialog} type="warning" />
      <Tr>
        <Td>
          <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
            {name}
          </Text>
        </Td>
        <Td>
          <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
            {description}
          </Text>
        </Td>
        <Td>
          <Text fontSize="ms" color={textColor} fontWeight="bold" pb=".5rem">
            {reportCategories?.map(val => val.name).join(", ")}
          </Text>
        </Td>
        <Td>
          <TableButton
            label="Editar"
            onClick={() => onEditForm(row)}
          />
        </Td>
        <Td>
          <TableButton
            label="Eliminar"
            onClick={() => { setOpenDeleteDialog(true) }}
          />
        </Td>
      </Tr>
    </>
  );
}

export default UserCategoryTableRow;
