import { useToast } from "@chakra-ui/react"
import { useRef, useState } from "react"
import AuthService from "services/AuthService"
import { getOrderParam } from "utils/filter"
import { cleanObject } from "utils/object"
import UserAdminViewService from "../services/UserViewService"
import UsersService from "../services/UsersService"

const useUsers = () => {
    const [data, setData] = useState([])
    const whereFilterRef = useRef()
    const orderRef = useRef([])

    const toast = useToast()

    async function importFromCsv(file, config) {
        let result = await UsersService.importFromCsv(file.filename, config)
        await getUsers();
        return result.data
    }

    async function getUsers(page) {
        const { list, count } = await UserAdminViewService.getList(page, {
            where: whereFilterRef.current,
            order: orderRef.current
        })
        setData({
            users: list,
            count
        })
    }

    function changeFilter(where) {
        whereFilterRef.current = where;
        getUsers()
    }

    function changeOrder(orderArray) {
        orderRef.current = getOrderParam(orderArray)
        getUsers()
    }

    async function createUser(data) {
        data.roles = [data.roles]

        if (data.roles[0] === "admin" && data.userCategoryId) {
            delete data.userCategoryId
        }

        if (data.userCategoryId) {
            data.userCategoryId = Number(data.userCategoryId)
        }

        const res = await AuthService.signUp(data)

        if (res.hasError) throw new Error("Error, intente nuevamente")

        toast({
            title: "Usuario creado",
            position: "bottom",
            status: "success",
            isClosable: true
        })
        getUsers()
    }

    async function updateUser(data) {
        const user = { ...data }

        delete user.id

        if (typeof user.roles == "object") {
            user.roles.flat()
        } else {
            user.roles = [user.roles]
        }

        if (user.userCategories) delete user.userCategories

        if (user.roles.includes("admin") && user.userCategoryId) {
            delete user.userCategoryId
        }

        if (user.userCategoryId) {
            user.userCategoryId = Number(user.userCategoryId)
        }

        await UsersService.update(data.id, cleanObject(user))
        toast({
            title: "Usuario actualizado",
            position: "bottom",
            status: "success",
            isClosable: true
        })
        getUsers()
    }

    async function deleteUser(id) {
        await UsersService.destroy(id)
        toast({
            title: "Usuario eliminado correctamente",
            status: "success",
        })
    }

    return {
        data,
        getUsers,
        createUser,
        updateUser,
        deleteUser,
        changeFilter,
        importFromCsv,
        changeOrder
    }
}

export default useUsers
