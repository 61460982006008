import {
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue
} from "@chakra-ui/react";
import AlertDialog from "components/Dialog";
import { useState } from "react";
import { TableButton } from "../Buttons";

function TagTableRow({ row, name, nameEnglish, onEditForm, getTags, deleteTag }) {
  const [openDialog, setOpenDialog] = useState(false)

  const textColor = useColorModeValue("gray.700", "white");

  async function onDeleteUser(action) {

    if (action === "accept") {
      await deleteTag(row.id)
    }

    setOpenDialog(false)
  }

  return (
    <Tr>
      <Td>
        <Flex direction="column">
          <Text textTransform="capitalize" fontSize="md" color={textColor} fontWeight="bold">
            {name}
          </Text>
        </Flex>

      </Td>
      <Td>
        <Flex direction="column">
          <Text textTransform="capitalize" fontSize="md" color={textColor} fontWeight="bold">
            {nameEnglish}
          </Text>
        </Flex>
      </Td>
      <Td>
        <TableButton
          label="Editar"
          onClick={() => onEditForm(row)}
        />
      </Td>
      <Td>
        <TableButton
          label="Eliminar"
          onClick={() => setOpenDialog(true)}
        />
      </Td>
      <AlertDialog
        isOpen={openDialog}
        onClick={onDeleteUser}
        title="Eliminar tag"
        body="¿Estás seguro de eliminar este tag? Esta acción es irreversible"
        type='warning'
      />
    </Tr>
  );
}

export default TagTableRow
