import { useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { getFileUrl } from 'utils/file';
import { fileUploadErrorHandler } from '../../../utils/errorHandlers';
import { FileUploader } from '../../FilerUploader/FileUploader';

function FileEditor({ blockDataObject, blockKey, setIsUsing, type }) {
  const [fileName, setFileName] = useState()
  const toast = useToast()

  useEffect(() => {
    if (fileName) {
      blockDataObject[blockKey].data = fileName
      setIsUsing(true)
    }
  }, [fileName])

  useEffect(() => {
    if (blockDataObject[blockKey] && blockDataObject[blockKey].data) {
      const reportImage = blockDataObject[blockKey].data
      setFileName(reportImage)
    }
  }, [])

  return (
    <FileUploader
      type={type}
      previewSrc={getFileUrl(fileName)}
      onUpload={(file) => setFileName(file.filename)}
      onDelete={() => setFileName()}
      onError={fileUploadErrorHandler(toast)}
    />
  )
}

export function ImageEditor(props) {
  return <FileEditor {...props} type={'image'} />
}

export function VideoEditor(props) {
  return <FileEditor {...props} type={'video'} />
}

export function ChartEditor(props) {
  return <FileEditor {...props} type={'chart'} />
}
