import { Tooltip } from "@chakra-ui/react";
import britishFlag from 'assets/img/britishFlag.jpg';
import spanishFlag from 'assets/img/spanishFlag.jpg';
import { LanguageEnum } from "constants/languaje";
import { TranslationIcon } from "../../components/TranslationIcon/TranslationIcon";

export function Flag({ language, style, onClick, showTranslationIcon = false, size = 1 }) {
    return (
        <Tooltip label={`${LanguageEnum.spanish === language ? 'Español' : 'Inglés'}${showTranslationIcon ? ' (Traducción)' : ''}`}>
            <div style={{ display: 'inline-block', position: 'relative', cursor: 'pointer' }} onClick={() => onClick(language)}>
                <img style={{ ...style, }} src={LanguageEnum.spanish === language ? spanishFlag : britishFlag} width={`${30 * size}px`} />
                <TranslationIcon show={showTranslationIcon} size={size} />
            </div>
        </Tooltip>
    )
}
