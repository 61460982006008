import { FaFileCsv } from "react-icons/fa";

export function CsvPreview({ style = {}, src = "" }) {
  return (
    <div style={{ ...style, display: 'flex', height: 'inherit', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <FaFileCsv fontSize={'3rem'} />
      <div style={{ width: '100%', maxHeight: '100%', overflowWrap: 'anywhere', overflowY: 'auto', padding: '5px', fontSize: '.8rem' }} >{src}</div>
    </div>
  )
}
