import CRUDService from "./CRUDService";

class UserService extends CRUDService {
  constructor() {
    super("users");
  }

  async importFromCsv(fileName, config) {
    return this.post(`/csv/${fileName}`, config)
  }

}

export default new UserService();
