import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import ReportService from "../services/ReportService"

export function useReportNavigation() {
  const history = useHistory()
  async function goToEdition(id, language) {
    if (!language) {
      language = (await ReportService.findById(id, { fields: ['language'] })).data?.language
    }
    const editionPath = `/admin/reports/${id}/edition/${language}`
    history.push(editionPath)
    window.location.reload()
  }
  function goToCreation() {
    history.push("/admin/reports/create")
  }
  return {
    goToCreation,
    goToEdition
  }
}
