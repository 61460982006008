import { LocalStorageKeys } from "../constants/localStorage";

export function clearTokens() {
  localStorage.removeItem(LocalStorageKeys.ACCESS_TOKEN)
  localStorage.removeItem(LocalStorageKeys.REFRESH_TOKEN)
}

export function getObject(key) {
  const stringObject = localStorage.getItem(key)
  if (!stringObject) return null
  let object = null
  try {
    object = JSON.parse(stringObject)
  } catch (error) {
    console.error(error)
  }
  return object
}

export function setObject(key, value) {
  return localStorage.setItem(key, JSON.stringify(value))
}
