import { Flex } from "@chakra-ui/react";
import { useEffect } from "react";
import CommentTableRow from "../../components/Rows/CommentTableRow";
import Table from "../../components/Table";
import useComments from "../../hooks/useComments";
import { FILTER_CONFIG, HEADERS, TITLE } from "./config";

const Comments = () => {
    const { data, getComments, changeFilter, changeOrder } = useComments()

    const onChangePage = (newPage) => getComments(newPage)

    useEffect(() => {
        getComments()
    }, [])
    return (
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
            <Table
                title={TITLE}
                count={data.count}
                captions={HEADERS}
                onChangePage={onChangePage}
                filterConfig={FILTER_CONFIG}
                onFilterChange={changeFilter}
                onChangeOrder={changeOrder}
            >
                {
                    data?.comments?.map((row) =>
                        <CommentTableRow
                            row={row}
                            name={row.userFullName}
                            report={row.reportTitle}
                            body={row.body}
                            date={row.createdAt}
                            category={row.reportCategoryName}
                        />
                    )
                }
            </Table>
        </Flex>
    )
}

export default Comments
