import { DeleteIcon, MinusIcon } from "@chakra-ui/icons";
import { Button, ButtonGroup, Icon, Tooltip } from "@chakra-ui/react";
import { BiImage, BiLineChart, BiText, BiVideo } from "react-icons/bi";

const styles = {
  ButtonGroupStyle: { display: 'flex', alignItems: 'end' },
  buttonStyle: { borderRadius: 30, width: 40, boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)' }
}

export default function Actions({ onClick, direction }) {
  return (
    // ver como cambiar le custom theme de la app
    <>
      <ButtonGroup gap={'5'} color={'black'} colorScheme="green" style={{ ...styles.ButtonGroupStyle, flexDirection: direction }} >
        <Tooltip label='Bloque de texto'>
          <Button style={styles.buttonStyle} onClick={() => onClick("addTextEditor")}>
            <Icon boxSize={5} as={BiText} />
          </Button>
        </Tooltip>
        <Tooltip label='Bloque de imagen'>
          <Button style={styles.buttonStyle} onClick={() => onClick("image")}>
            <Icon boxSize={5} as={BiImage} />
          </Button >
        </Tooltip>
        <Tooltip label='Bloque de gráficos'>
          <Button style={styles.buttonStyle} onClick={() => onClick("chart")}>
            <Icon boxSize={5} as={BiLineChart} />
          </Button >
        </Tooltip>
        <Tooltip label='Bloque de video'>
          <Button style={styles.buttonStyle} onClick={() => onClick("video")}>
            <Icon boxSize={5} as={BiVideo} />
          </Button >
        </Tooltip>
        <Tooltip label='Bloque separador'>
          <Button style={styles.buttonStyle} onClick={() => onClick("addSeparator")}>
            <MinusIcon boxSize={5} />
          </Button>
        </Tooltip>
        <Tooltip label='Borrar bloque'>
          <Button style={styles.buttonStyle} onClick={() => onClick("delete")}>
            <DeleteIcon boxSize={5} />
          </Button >
        </Tooltip>
      </ButtonGroup>
    </>
  )
}
