import { BiLogOut, BiSolidCategory } from "react-icons/bi";
import { FaUser } from "react-icons/fa";
import { HiClipboardDocumentList } from 'react-icons/hi2';
import { IoClipboard, IoPricetag } from "react-icons/io5";
import SignIn from "views/Auth/SignIn";
import Comments from "views/Comentaries";
import CommentResponses from "views/CommentResponses/CommentResponses";
import ReportCategory from "views/ReportCategory";
import ReportCreation from "views/ReportCreation/ReportCreation";
import Reports from "views/Reports";
import Tags from "views/Tags";
import UserCategory from "views/UserCategory";
import Users from "views/Users";
import {
  StatsIcon
} from "./components/Icons/Icons";

var dashRoutes = [
  {
    path: "/users",
    name: "Usuarios",
    icon: <FaUser color="inherit" />,
    component: Users,
    layout: "/admin",
  },
  {
    path: "/user-category",
    name: "Categorías de usuario",
    icon: <BiSolidCategory color="inherit" />,
    component: UserCategory,
    layout: "/admin",
  },
  {
    path: "/report-category",
    name: "Categorías de reporte",
    icon: <BiSolidCategory color="inherit" />,
    component: ReportCategory,
    layout: "/admin",
  },
  {
    path: "/reports",
    name: "Reportes",
    icon: <HiClipboardDocumentList color="inherit" />,
    component: Reports,
    layout: "/admin",
    exact: true
  },
  {
    path: '/reports/create',
    name: 'Crear reportes',
    icon: <StatsIcon color="inherit" />,
    component: ReportCreation,
    layout: '/admin',
    visibility: 'hidden',
  },
  {
    path: '/reports/:id/edition/:language',
    name: 'Editar reporte',
    icon: <StatsIcon color="inherit" />,
    component: ReportCreation,
    layout: '/admin',
    visibility: 'hidden',
    exact: true
  },
  {
    path: '/tags',
    name: 'Tags',
    icon: <IoPricetag color="inherit" />,
    component: Tags,
    layout: '/admin',
  },
  {
    path: '/comments',
    name: 'Comentarios',
    icon: <IoClipboard color="inherit" />,
    component: Comments,
    layout: '/admin',
    exact: true
  },
  {
    path: '/comments/:reportId/:userId',
    name: 'Respuestas',
    icon: <StatsIcon color='inherit' />,
    component: CommentResponses,
    layout: '/admin',
    visibility: 'hidden',
    exact: true
  },
  {
    path: "/signin",
    name: "Cerrar sesión",
    icon: <BiLogOut color="inherit" />,
    component: SignIn,
    layout: "/admin/auth",
  }
];
export default dashRoutes;
