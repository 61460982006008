import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button
} from '@chakra-ui/react'
import React from 'react'

/**
 *
 * @param {object} param
 * @param {'normal'|'warning'} param.type
 * @param {boolean} param.isOpen
 * @param {string} param.title
 * @param {string} param.body
 * @param {boolean} param.loading
 * @param {(action:'accept'|'cancel')=>any} param.onClick
 * @returns
 */
export default function AlertDialogComponent({ isOpen, onClick, title, body, loading = false, type = 'normal' }) {
  const cancelRef = React.useRef()
  return (
    <>
      <AlertDialog
        motionPreset='slideInBottom'
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClick}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>
              {body?.split('<br/>').map((line, index) => (
                <p key={index}>{line}</p>
              ))}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                isDisabled={loading}
                isLoading={loading}
                ref={cancelRef}
                onClick={() => onClick("cancel")}>
                Cancelar
              </Button>
              <Button
                isDisabled={loading}
                isLoading={loading}
                colorScheme={type === 'warning' ? 'red' : 'green'}
                onClick={() => onClick("accept")}
                ml={3}>
                Aceptar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
