import {
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormErrorMessage
} from '@chakra-ui/react'
import { Contained, Outlined } from 'components/Buttons'
import Drawer from 'components/Drawer'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import Strategy from '../../components/Strategy'

export default function Form({
  open,
  toggleModal,
  create,
  update,
  defaultValues,
  initialData,
}) {
  const {
    handleSubmit,
    register,
    trigger,
    setError,
    clearErrors,
    watch,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm(defaultValues ? { defaultValues } : {})
  //formValues it is used to send additional values of multiple select
  const [formValues, setFormValues] = useState({})
  const watchAllFields = watch()

  const handleUserAction = async () => {
    const noErr = await trigger()

    if (noErr) {
      await handleSubmit(
        defaultValues
          ? (data) => update(data, formValues)
          : (data) => create(data, formValues))()
      toggleModal()
    }
  }

  function checkVisibility(props) {

    //if no dependecy, show the field
    if (!props.dependency) return true

    //if no value set for dependency, do not show
    if (!watchAllFields[props.dependency]) return false

    //Se unifican tipos. Cuando los roles vienen del backend son un array. Cuando los roles se modifican el formulario pasan a string
    let field = Array.isArray(watchAllFields[props.dependency]) ? watchAllFields[props.dependency][0] : watchAllFields[props.dependency]

    //if dependency is equal to dependency value, show the field
    if (field === props.dependencyValue || props.dependencyValue.includes(field)) {
      return true
    }
    if (!defaultValues) return false
    //Se unifican tipos. Cuando los roles vienen del backend son un array. Cuando los roles se modifican el formulario pasan a string
    field = Array.isArray(defaultValues[props.dependency]) ? defaultValues[props.dependency][0] : defaultValues[props.dependency]

    //if it is in edit mode
    if (defaultValues) {
      //if dependency is NOT equal to dependency value, do NOT show the field
      if (field !== props.dependencyValue || !props.dependencyValue.includes(field)) {
        delete defaultValues[props.dependency]
        return false
      }

      if (props.dependency in defaultValues) {
        if (field === props.dependencyValue || props.dependencyValue.includes(field)) {
          return true
        }
      }
    }
  }

  return (
    <Drawer
      title={defaultValues ? "Editar" : "Crear"}
      isOpen={open}
      onClose={toggleModal}
    >
      <DrawerBody>
        {/* if has dependency & that dependencyValue y equal to the selected "watched" value, show the field */}
        {initialData.map(props => checkVisibility(props) && (
          <FormControl mb="16px" key={props.id} isInvalid={errors[props.id]}>
            {props.type === "MUL"
              ? <Strategy
                setFormValues={setFormValues}
                setError={setError}
                clearErrors={clearErrors}
                register={register}
                {...props} />
              : <Strategy register={register} {...props} />}
            <FormErrorMessage>
              {errors[props.id] && errors[props.id].message}
            </FormErrorMessage>
          </FormControl>
        ))}
      </DrawerBody>
      <DrawerFooter justifyContent="space-around">
        <Outlined
          w="48%"
          onClick={toggleModal}>
          Cancelar
        </Outlined>
        <Contained
          w="48%"
          isLoading={isSubmitting}
          onClick={handleUserAction}>
          {defaultValues ? "Editar" : "Crear"}
        </Contained>
      </DrawerFooter>
    </Drawer>
  )
}
