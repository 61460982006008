import { ChartEditor, ImageEditor, VideoEditor } from 'components/ReportCreation/editors/FileEditors';
import Separator from 'components/ReportCreation/editors/Separator';
import TextEditor from 'components/ReportCreation/editors/TextEditor';
import { useRef, useState } from 'react';
let counter = 0

export const useBlockManager = () => {
    const [Blocks, setBlocks] = useState([]);
    /**
   * @typedef BlockInfo
   * @property {number} index - El índice del bloque.
   * @property {string} [data] - Datos asociados al bloque (opcional).
   * @property {'text'|'image'|'separator'|'video'} type - Tipo del bloque.
   *
   * @example
   *  {
   *    32:{
   *      index: 1,
   *      data: "some data",
   *      type: "text"
   *    }
   *  }
   */
    /**
     * Es el objeto ref donde se almacena la información de bloques. ***Cada bloque maneja internamente la actualizacion***
     * @type {React.MutableRefObject<BlockInfo>}
     */
    const blockDataObjectRef = useRef({});

    /**Genera bloques a partir del body del reporte */
    const generateBlocks = (reportBody) => {
        if (!reportBody) return
        let _blocks = []
        for (let [i, reportBodyElement] of reportBody.entries()) {
            const blockKey = counter++;
            blockDataObjectRef.current[blockKey] = reportBodyElement
            switch (reportBodyElement.type) {
                case 'text':
                    _blocks.push({ Component: TextEditor, name: 'Bloque de texto', type: reportBodyElement.type, key: blockKey })
                    break
                case 'image':
                    _blocks.push({ Component: ImageEditor, name: 'Bloque de imagen', type: reportBodyElement.type, key: blockKey })
                    break
                case 'chart':
                    _blocks.push({ Component: ChartEditor, name: 'Bloque de gráfico', type: reportBodyElement.type, key: blockKey })
                    break
                case 'video':
                    _blocks.push({ Component: VideoEditor, name: 'Bloque de video', type: reportBodyElement.type, key: blockKey })
                    break
                case 'separator':
                    _blocks.push({ Component: Separator, name: 'Bloque Separador', type: reportBodyElement.type, key: blockKey })
                    break
            }
        }
        setBlocks(_blocks)
    }

    /**
    * Elimina componete
    * @param index Indice del componente que se quiere eliminar. Si no se pasa elimina el ultimo
    */
    const deleteBlock = (index) => {
        if (Blocks.length === 0) { return }
        const maxIndex = Blocks.length - 1
        index = index ?? maxIndex
        //Elimino sus datos
        let blockKey
        //Busco por indice
        Object.keys(blockDataObjectRef.current).forEach(function (key) {
            const blockData = blockDataObjectRef.current[key];
            if (blockData.index === index) {
                blockKey = key
                return
            }
        });
        delete blockDataObjectRef.current[String(blockKey)]
        //Elimino el bloque
        setBlocks((prevBlocks) => prevBlocks.filter((_, i) => i !== index));
    }

    /**
   * Agrega componentes
   * @param Block Componente a agregar
   * @param index Opcional. Indice donde se quiere agregar. Por defecto se agrega al final
   */
    const addBlock = (Block, index) => {
        counter++;
        Block.key = counter;
        const maxIndex = Blocks.length;
        if (index === undefined) index = maxIndex;
        if (maxIndex < index) throw new Error("Index Error");
        const newComponents = [...Blocks];
        newComponents.splice(index, 0, Block);
        setBlocks(newComponents);
    }

    /**
     * Mueve componentes de una posición a otra
     * @param index Indice del elemento a mover
     * @param options Opciones de movimiento
     * @returns
    */
    const moveBlock = (index, options, key) => {
        const maxIndex = Blocks.length - 1
        switch (options?.action) {
            case 'up':
                options.destinationIdx = index - 1
                break;
            case 'down':
                options.destinationIdx = index + 1
                break;
        }
        if (options.destinationIdx === undefined || options.destinationIdx < 0 || options.destinationIdx > maxIndex) return;
        const newComponents = [...Blocks];
        const component = newComponents[index];
        newComponents.splice(index, 1);
        newComponents.splice(options?.destinationIdx, 0, component);
        setBlocks(newComponents)
    }

    return {
        Blocks,
        blockDataObjectRef,
        addBlock,
        moveBlock,
        deleteBlock,
        generateBlocks,
    };
};
