import moment from "moment"
import '../../views/CommentResponses/pxq-admin.webflow.css'

const Comment = ({ comment }) => {
  return (
    <div className={comment?.destinationId ? 'wrappercomentario' : 'wrapperrespuesta'}>
      <div className={comment?.destinationId ? 'div-block-4' : 'div-block-5'}>
        <div className={comment?.destinationId ? "comentario" : "comentario respuesta"}>

          <div className="text-block-2">
            {moment(comment?.createdAt).format('DD/MM/YYYY, h:mm a')}
          </div>

          <p className="paragraph">
            {comment?.body}
          </p>
          {
            comment?.destinationId ?
              <div className="text-block-3">
                Respondido por: {comment.hiddenUser ? 'pxq |' : null} &nbsp;{`${comment.user.firstName} ${comment.user.lastName}`}
              </div>
              :
              null
          }
        </div>
      </div>
    </div>
  )
}

export default Comment
