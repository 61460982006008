import { useToast } from "@chakra-ui/react"
import { useRef, useState } from "react"
import ReportCategoryService from "services/ReportCategoryService"
import { getOrderParam } from "utils/filter"

const useReportCategory = () => {
    const [data, setData] = useState([])
    const whereFilterRef = useRef()
    const orderRef = useRef()

    const toast = useToast()

    async function getReportCategories(page) {
        const { list, count } = await ReportCategoryService.getList(page, { where: whereFilterRef.current, order: orderRef.current })
        setData({
            reportCategories: list,
            count
        })
    }


    function changeFilter(where) {
        whereFilterRef.current = where;
        getReportCategories()
    }

    function changeOrder(orderArray) {
        orderRef.current = getOrderParam(orderArray)
        getReportCategories()
    }

    async function createReportCategory(data) {
        await ReportCategoryService.create(data)
        toast({
            title: "Categoría de reporte creada",
            position: "bottom",
            status: "success",
            isClosable: true
        })
        getReportCategories()
    }

    async function updateReportCategory(data) {
        const reportCategory = { ...data }

        delete reportCategory.id
        delete reportCategory.createdAt
        delete reportCategory.updatedAt

        await ReportCategoryService.updateFields(data.id, reportCategory)
        toast({
            title: "Categoría de usuario actualizada",
            position: "bottom",
            status: "success",
            isClosable: true
        })
        getReportCategories()
    }

    async function deleteUserCategory(id) {
        await ReportCategoryService.destroy(id)
        toast({ title: 'Categoría eliminada', status: 'success' })
        await getReportCategories()
    }

    return {
        data,
        getReportCategories,
        createReportCategory,
        updateReportCategory,
        deleteUserCategory,
        changeFilter,
        changeOrder
    }
}

export default useReportCategory
