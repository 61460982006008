import CRUDService from "./CRUDService";

class AuthService extends CRUDService {
  constructor() {
    super("authentication");
  }

  async login(data) {
    return await this.post("/refresh-login", data)
  }

  async findUser() {
    return await this.get("/whoAmI")
  }

  async refresh(refreshToken) {
    return await this.post("/refresh", { refreshToken })
  }

  async signUp(data) {
    return await this.post("/signup", data)
  }

  async changePassword(password) {
    return await this.post("/change-password", { password })
  }
}

export default new AuthService();
