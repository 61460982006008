export const initialUserCategoryFormData = [
    {
        id: "name",
        type: "INP",
        placeholder: "",
        label: "Nombre",
        validations: {
            required: 'Campo requerido',
        }
    },
    {
        id: "description",
        type: "INP",
        placeholder: "",
        label: "Descripción",
    },
    {
        id: "reportCategoryIds",
        type: "MUL",
        placeholder: "",
        label: "Seleccionar categorías de reporte",
        options: [],
        validations: {
            required: 'Campo requerido',
        }
    }
]

export const HEADERS = [
    { label: "Nombre", fieldName: 'name', sortable: true },
    { label: "Descripción", fieldName: 'description', sortable: true },
    "Categorías de reporte",
    "",
    ""]
export const TITLE = "Categorías de usuario"

export const FILTER_CONFIG = [
    { label: 'Nombre', type: 'string', visible: true, fieldName: 'name' },
    { label: 'Descripción', type: 'string', visible: true, fieldName: 'description' },
]
