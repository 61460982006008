
export const HEADERS = [
    { label: "Nombre", fieldName: 'userFullName', sortable: true },
    { label: "Informe", fieldName: 'reportTitle', sortable: true },
    { label: "Comentario", fieldName: 'body', sortable: true },
    { label: "Fecha", fieldName: 'createdAt', sortable: true },
    { label: "Categoría", fieldName: 'reportCategoryName', sortable: true },
]

export const TITLE = "Comentarios"

export const FILTER_CONFIG = [
    {
        label: 'Nombre', type: 'string', visible: true, fieldName: 'userFullName'
    },
    {
        label: 'Informe', type: 'string', visible: true, fieldName: 'reportTitle'
    },
    {
        label: 'Comentario', type: 'string', visible: true, fieldName: 'body'
    },
    {
        label: 'Fecha', type: 'date', visible: true, fieldName: 'createdAt'
    },
    {
        label: 'Categoría', type: 'string', visible: true, fieldName: 'reportCategoryName'
    }
]



