import { Flex } from "@chakra-ui/react";
import Form from "components/Form";
import { useEffect, useState } from "react";
import TagTableRow from "../../components/Rows/TagTableRow";
import Table from "../../components/Table";
import useTags from "../../hooks/useTags";
import { FILTER_CONFIG, HEADERS, TITLE, initialTagFormData } from "./config";

const Tags = () => {
    const [open, setOpen] = useState(false)
    const [selectedTag, setSelectedTag] = useState(null)//?
    const { data, getTags, createTags, updateTags, deleteTag, changeFilter, changeOrder } = useTags()

    const toggleModal = () => setOpen(open => {
        if (open) {
            setSelectedTag(null)
        }
        return !open
    })

    const onChangePage = (newPage) => getTags(newPage)

    const onEditForm = (tags) => {
        setSelectedTag(() => {
            return tags
        })
        getTags()
        toggleModal()
    }

    useEffect(() => {
        getTags()
    }, [])
    return (
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
            {
                open && (
                    <Form
                        open={open}
                        toggleModal={toggleModal}
                        create={createTags}
                        update={updateTags}
                        initialData={initialTagFormData}
                        defaultValues={selectedTag}
                    />
                )
            }
            <Table
                title={TITLE}
                count={data.count}
                captions={HEADERS}
                openForm={toggleModal}
                onChangePage={onChangePage}
                filterConfig={FILTER_CONFIG}
                onFilterChange={changeFilter}
                onChangeOrder={changeOrder}
            >
                {
                    data?.tags?.map((row, idx) =>
                        <TagTableRow
                            row={row}
                            name={`${row.name}`}
                            nameEnglish={`${row.name_en}`}
                            onEditForm={onEditForm}
                            getTags={getTags}
                            deleteTag={deleteTag}
                        />
                    )
                }
            </Table>
        </Flex >
    )
}

export default Tags
