export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}


export const shortText = (str) => {
  if (str.length > 40) {
    return str.substring(0, 40) + "...";
  }
  return str
}
