import _ from 'lodash';

/**
 * Elimina propiedades null del objeto.
 * @param {object} object
 * @returns
 */
export function cleanObject(object) {
  const clonedObj = _.cloneDeep(object)
  for (const key in clonedObj) {
    if (Object.hasOwnProperty.call(clonedObj, key)) {
      const value = clonedObj[key];
      if (value === null | value === "") delete clonedObj[key]
    }
  }
  return clonedObj
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}
