/** La propiedad header puede ser `string`|`object` la función unifica a `object`.*/
export function convertToHeaderObject(header) {
    return typeof header === 'string' ? { label: header } : header
}

/**Busca dentro `orderArray` el criterio de orden del campo `fieldName`*/
export function getOrderCriteriaByFieldName(fieldName, orderArray) {
    if (!Array.isArray(orderArray)) return null
    return orderArray?.find(o => o?.fieldName === fieldName)?.orderCriteria
}

/**Devuelve el índice de `fieldName` en `orderArray` */
export const getIndexByFieldName = (fieldName, orderArray) => {
    if (!Array.isArray(orderArray)) return null
    const index = orderArray?.findIndex(o => o?.fieldName === fieldName)
    return index === -1 ? null : index
}
