import axios from "axios";
// import { eventEmitter } from '../App';
import API_URL from "utils/config";
import { clearTokens } from "utils/localStorage";
import { LocalStorageKeys } from "../constants/localStorage";
import AuthService from "./AuthService";
// import AuthService from "./AuthService";

function responseIsSuccessful({ status }) {
  let successful = false;
  if (status >= 200 && status < 300) {
    successful = true;
  }
  return successful;
}

class HTTPService {
  static defaultHeader = null
  constructor(baseRouteUrl) {
    this.http = axios.create();
    this.http.defaults.baseURL = API_URL + (baseRouteUrl || "");
    const defaultHeader = HTTPService.defaultHeader
    if (defaultHeader) this.setDefaultHeader(defaultHeader.header, defaultHeader.value)
    this.http.interceptors.request.use(this.handleRequestInterceptor);
    this.http.interceptors.response.use(null, this.handleErrorInterceptor)
    this.get = this.createRequestHandler("get");
    this.post = this.createRequestHandler("post");
    this.put = this.createRequestHandler("put");
    this.patch = this.createRequestHandler("patch");
    this.delete = this.createRequestHandler("delete");
  }

  createRequestHandler(verb) {
    return async function (...args) {
      let hasError = null;
      let data = null;
      let statusCode = null;
      let msg = "";
      try {
        const response = await this.http[verb](...args);
        if (!responseIsSuccessful(response)) {
          hasError = true;
          // msg = response.data?.detail || "error";
        } else {
          data = response.data;
        }
        statusCode = response.status;
      } catch (err) {
        hasError = true;
        msg = err.response?.data?.error?.userMessage || "Ha surgido un error";
        console.error(err)
        throw err
      }
      return { data, hasError, msg, statusCode };
    };
  }

  /**
   * Setea cabecera por defecto, únicamente de la instancia
   * @param {*} header
   * @param {*} value
  */
  setDefaultHeader(header, value) {
    this.http.defaults.headers.common[header] = value;
  }

  /**
   * Setea cabecera por defecto para todas las instancias que se creen posteriormente al seteo. Setea también la de la instancia que ejecuta el método
   * @param {*} header
   * @param {*} value
  */
  setDefaultHeaderAllInstances(header, value) {
    HTTPService.defaultHeader = { header, value }
    this.setDefaultHeader(header, value)
  }

  setDefaultBaseUrl(url = "") {
    this.http.defaults.baseURL = API_URL + url + "/";
  }

  handleErrorInterceptor = async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401) {
      try {
        if (originalRequest && !originalRequest._retry && originalRequest.url !== "/refresh") {
          //get refreshToken
          const refreshToken = localStorage.getItem(LocalStorageKeys.REFRESH_TOKEN)

          // get new accessToken
          if (refreshToken) {
            const res = await AuthService.refresh(refreshToken)
            localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, res.data.accessToken)

            originalRequest._retry = true;

            // shoot request again
            const newRequest = await this.setAuthHeaderToConfig(originalRequest)
            const result = await this.http(newRequest)

            return result
          }
        }
      } catch (err) {
        console.error(err);
        throw error;
      }

      clearTokens()
      window.location.reload()
    }
    throw error;
  }

  handleRequestInterceptor = async (config) => {
    return await this.setAuthHeaderToConfig(config);
  };

  /**
   * Asigna cabecera de authenticación a una `requestConfig` de `axios`. Toma el accesToken del `asyncStorage`
   * @param {*} config
   * @returns
  */

  setAuthHeaderToConfig = (config) => {
    const accessToken = this.getAccessToken();
    if (accessToken) {
      config.headers = {
        ...config.headers,
        //Se añadió esta cabecera para identificar el origen de las request y evitar traducir lo que provenga del administrador.
        "X-App-Origin": 'admin',
        Authorization: `Bearer ${accessToken}`,
      };
    }
    return config
  }

  getAccessToken() {
    return localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN);
  }
}

export default HTTPService;
