// Chakra imports
import { Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Form from "../../components/Form";
import ReportCategoryTableRow from "../../components/Rows/ReportCategoryTableRow";
import Table from "../../components/Table";
import useReportCategory from "../../hooks/useReportCategory";
import { FILTER_CONFIG, HEADERS, TITLE, initialReportCategoryFormData } from "./config";

function ReportCategory() {
    const [open, setOpen] = useState(false)
    const [selectedReportCategory, setSelectedReportCategory] = useState(null)
    const { data, getReportCategories, createReportCategory, updateReportCategory, deleteUserCategory, changeFilter, changeOrder } = useReportCategory()

    const toggleModal = () => setOpen(open => {
        if (open) {
            setSelectedReportCategory(null)
        }
        return !open
    })

    const onChangePage = (newPage) => getReportCategories(newPage)

    const onEditForm = (reportCategory) => {
        setSelectedReportCategory(reportCategory)
        toggleModal()
    }

    useEffect(() => {
        getReportCategories()
    }, [])

    return (
        <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
            {open && (
                <Form
                    open={open}
                    toggleModal={toggleModal}
                    create={createReportCategory}
                    update={updateReportCategory}
                    defaultValues={selectedReportCategory}
                    initialData={initialReportCategoryFormData}
                />
            )}
            <Table
                title={TITLE}
                count={data.count}
                captions={HEADERS}
                openForm={toggleModal}
                onChangePage={onChangePage}
                filterConfig={FILTER_CONFIG}
                onFilterChange={changeFilter}
                onChangeOrder={changeOrder}
            >
                {data?.reportCategories?.map((row) =>
                    <ReportCategoryTableRow
                        row={row}
                        key={row.name}
                        name={row.name}
                        description={row.description}
                        getReportCategories={getReportCategories}
                        isDailyPublished={row.isDailyPublished}
                        morningFeatured={row.morningFeatured}
                        eveningFeatured={row.eveningFeatured}
                        onEditForm={onEditForm}
                        deleteUserCategory={deleteUserCategory}
                    />
                )}
            </Table>
        </Flex>
    );
}

export default ReportCategory;
