export const initialUserFormData = [
    {
        id: "firstName",
        type: "INP",
        placeholder: "John",
        label: "Nombre",
        validations: {
            required: 'Campo requerido',
        }
    },
    {
        id: "lastName",
        type: "INP",
        placeholder: "Doe",
        label: "Apellido",
        validations: {
            required: 'Campo requerido',
        }
    },
    {
        id: "email",
        type: "INP",
        placeholder: "john@doe.com",
        label: "Email",
        validations: {
            required: 'Campo requerido',
        }
    },
    {
        id: "roles",
        type: "SEL",
        label: "Seleccionar rol",
        options: [{ name: "Administrador", value: "admin" }, { name: "Cliente", value: "customer" }, { name: "Editor", value: "editor" }, { name: "Moderador", value: "moderator" }],
        validations: {
            required: 'Campo requerido',
        }
    },
    {
        id: "userCategoryId",
        type: "SEL",
        label: "Seleccionar categoría",
        options: [],
        validations: {
            required: 'Campo requerido',
        },
        dependency: "roles",
        dependencyValue: ["customer", "editor"]
    },
    {
        id: "status",
        type: "SEL",
        label: "Seleccionar estado",
        options: [{ name: "Activo", value: "active" }, { name: "Inactivo", value: "inactive" }],
        validations: {
            required: 'Campo requerido',
        },
    },
    {
        id: "companyName",
        type: "INP",
        placeholder: "ABC SRL",
        label: "Empresa",
        validations: {}
    },
]

export const HEADERS = [
    { label: "Nombre", fieldName: 'fullName', sortable: true },
    { label: "Rol", fieldName: 'rol', sortable: true },
    { label: "Estado", fieldName: 'status', sortable: true },
    { label: "Alta", fieldName: 'createdAt', sortable: true },
    { label: "Empresa", fieldName: 'companyName', sortable: true },
    "",
    ""]

export const TITLE = "Usuarios"

export const ROLES = {
    admin: "Administrador",
    customer: "Cliente",
    editor: "Editor",
    moderator: "Moderador"
}

export const FILTER_CONFIG = [
    { label: 'Nombre', type: 'string', visible: true, fieldName: 'fullName' },
    { label: 'Email', type: 'string', visible: true, fieldName: 'email' },
    {
        label: 'Rol', type: 'select', visible: true, fieldName: 'rol',
        options: [
            {
                value: "admin",
                label: 'Administrador'
            },
            {
                value: "moderator",
                label: 'Moderador'
            },
            {
                value: "editor",
                label: 'Editor'
            },
            {
                value: "customer",
                label: 'Cliente'
            },
        ]
    },
    { label: 'Fecha', type: 'date', visible: true, fieldName: 'createdAt' },
    { label: 'Empresa', type: 'string', visible: true, fieldName: 'companyName' },
]
