import { getIndexByFieldName, getOrderCriteriaByFieldName } from 'components/Table/components/Headers/utils';
import _ from 'lodash'; // Asegúrate de tener lodash instalado
import { useState } from 'react';

const defaultPosibleOrders = ['ASC', 'DESC', null];

/**
 * Hook para el manejo de criterio de orden en las tablas
 * @param {*} param0
 * @returns
 */
export function useOrder({ initialOrder = [], orderRestriction, posibleOrders = defaultPosibleOrders }) {
    const [orderArray, setOrderArray] = useState(initialOrder);

    /** Añade un nuevo criterio de orden al array para un campo específico.
     *  * Sobreescribe si ya esxiste orden para ese campo.
     *  * Elimina del array si el criterio de orden es `null` */
    function addOrderCriteria(orderCriteria, fieldName) {
        let orderArrayCopy = _.cloneDeep(orderArray)
        //La restricción only-one permite solo un criterio de orden a la vez. Por eso el array de resetea cada vez.
        if (orderRestriction === 'only-one') orderArrayCopy = []
        if (!fieldName) return orderArrayCopy
        const index = getIndexByFieldName(fieldName, orderArrayCopy)
        const orderObject = { fieldName, orderCriteria }
        const deleteElement = !orderCriteria
        //No existe en el array.
        if (index === null) {
            if (!deleteElement) orderArrayCopy.push(orderObject)
        }
        //Existe en el array
        else {
            //Si existe actualiza.
            orderArrayCopy[index] = orderObject
            if (deleteElement) orderArrayCopy.splice(index, 1);
        }
        setOrderArray(orderArrayCopy)
        return orderArrayCopy
    }

    /**Cambia el criterio de orden de la columna de forma cíclica, según el array `posibleOrders`*/
    function toggleColumnOrder(headerObject) {
        const currentCriteria = getOrderCriteriaByFieldName(headerObject?.fieldName, orderArray)
        let index = posibleOrders.indexOf(currentCriteria)
        index++;
        if (index > posibleOrders.length - 1) index = 0
        const newCriteria = posibleOrders[index]
        addOrderCriteria(newCriteria, headerObject?.fieldName)
    }

    function resetOrder() {
        setOrderArray(initialOrder)
    }

    return { orderArray, toggleColumnOrder, resetOrder };
}
