import { Button, useColorModeValue } from "@chakra-ui/react"
import { useRef, useState } from "react"
import { MdCancel } from "react-icons/md"
import { FilterStrategy } from "./FilterStrategy"
import { dateFilterHandler } from "./FilterTypes/Date/dateFilterHandler"
import { numberFilterHandler } from "./FilterTypes/Number/numberFilterHandler"
import { selectFilterHandler } from "./FilterTypes/Select/selectFilterHandler"
import { textFilterHandler } from "./FilterTypes/Text/textFilterHandler"

/**
 * Componente de filtros de tablas.
 * * Pasa por parámetro en el `onChange` el filtro para la request
 * * Recibe la configuración de filtrado de columnas en la propiedad `columns`
 * @param {{
 * onChange:(filter:WhereFilter)=>unknown
 * columns:{
 * label: string,
 * type: 'string' | 'number' | 'date',
 * fieldName: 'string'
 * visible: boolean,
 * customFilterHandler:(filter:Filter)=>void
 *  }[]}} param0
 * @returns
 */
export function Filter({ columns, onChange = (filter) => { } }) {
  const [inputsKey, setInputsKey] = useState(true)
  const filter = useRef({})
  if (!columns?.length) return
  const gray = useColorModeValue("gray.600", "gray");


  /**Manejador de eventos onChange de los inputs */
  async function onChangeInputs(e) {
    const fieldName = e.target.id
    const value = e.target.value
    const column = columns.find((column) => column.fieldName === fieldName)
    const type = column?.type
    //Si la columna a filtrar tiene un customHandler se utiliza ese. Si no el default según el tipo
    const filterHandler = column.customFilterHandler ?? filterHandlerMap[type]
    //Algunos handlers pueden ser asíncronos.
    if (filterHandler) await filterHandler(filter, fieldName, value)
    //Devuelve el nuevo filtro al padre
    onChange(filter.current)
  }

  /**Restablecimiento de filtros */
  const resetFilters = () => {
    //Reset del filtro
    filter.current = {}
    //Permite el reseteo de todos los inputs
    setInputsKey(!inputsKey)
    //Devuelve el nuevo filtro al padre
    onChange(filter.current)
  }

  /** Evita múltiples request al servidor */
  const debouncedOnChange = _.debounce(onChangeInputs, 500)

  return (
    <div>
      <div key={inputsKey} style={{ display: 'flex', alignItems: 'center' }}>
        {columns.map((column, index) => <FilterStrategy column={column} onChange={debouncedOnChange} key={index} fontSize={"small"} maxWidth={"15rem"} height={"2rem"} />)}
      </div>
      {(filter?.current && Object.keys(filter.current).length) ?
        < Button leftIcon={<MdCancel fontSize={15} color="IndianRed" />} size="xs" color={gray} backgroundColor={'transparent'} onClick={resetFilters}>Restablecer</Button>
        : null
      }
    </div >
  )
}

/** Permite el manejo del filtro según cada tipo de filtro. Pueden pasarse en la configuración
 * `customFilterHandler` para comportamientos diferentes a los que se ofrecen por defecto
 * */
export const filterHandlerMap = {
  string: textFilterHandler,
  number: numberFilterHandler,
  date: dateFilterHandler,
  select: selectFilterHandler,
}
