import { useToast } from '@chakra-ui/react';
import { AppContext } from 'context';
import { useContext } from 'react';

export const usePromise = () => {
  const { setLoading } = useContext(AppContext)
  const toast = useToast()

  const handlePromise = async (promise, { time = 0, } = {}) => {
    let res = null;
    try {
      setLoading(true)
      res = await promise()
      return res;
    } catch (error) {
      console.error(error)
      throw error
    }
    finally {
      setTimeout(() => {
        setLoading(false)
      }, time);
    }
  }

  return handlePromise;
}

