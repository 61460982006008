import { useToast } from "@chakra-ui/react"
import { useRef, useState } from "react"
import CommentService from "services/CommentService"
import CommentViewService from "services/CommentViewService"
import { getOrderParam } from "utils/filter"
import { cleanObject } from "utils/object"

const useComments = () => {
    const [data, setData] = useState([])
    const toast = useToast()
    const whereFilterRef = useRef()
    const orderRef = useRef()

    async function getComments(page) {
        const { list, count } = await CommentViewService.getList(page, {
            where: {
                ...whereFilterRef.current
            },
            order: orderRef.current
        })
        setData({
            comments: list,
            count,
        })
    }

    function changeFilter(where) {
        whereFilterRef.current = where;
        getComments()
    }

    function changeOrder(orderArray) {
        orderRef.current = getOrderParam(orderArray)
        getComments()
    }

    async function createComments(data) {
        const res = await CommentService.create(data)
        if (res.hasError) throw new Error("Error, intente nuevamente")
        toast({
            title: "Comentario creado",
            position: "bottom",
            status: "success",
            isClosable: true
        })
        getComments()
    }

    async function updateComments(data) {
        const comment = { ...data }
        delete comment.id

        await CommentService.update(data.id, cleanObject(comment))
        toast({
            title: "Comentario editado",
            position: "bottom",
            status: "success",
            isClosable: true
        })
        getComments()
    }

    async function deleteComentarie(id) {
        await CommentService.destroy(id)
        toast({
            title: "Comentario eliminado correctamente",
            status: "success"
        })
        await getComments()
    }

    return {
        data,
        getComments,
        changeFilter,
        changeOrder
    }
}

export default useComments
