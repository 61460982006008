import { Box } from "@chakra-ui/react";
import { useState } from "react";
import { Flag } from "../../components/Flag/Flag";
import { LanguageEnum } from "../../constants/languaje";

const styles = {
  imagesContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '200px',
  },
  bar: {
    top: `${75 / 2 + 18}px`,
    transform: 'translateY(-50%)',
    zIndex: 2, width: "calc(100vw - 75px - 275px)",
    display: 'flex',
    justifyContent: 'end',
  }
}

export function LanguageSelector({ onChange = (language) => { }, value, isTranslation = false }) {
  const selectedLanguage = (language) => {
    if (language === value) {
      return ({
        filter: 'grayscale(0%)',
        opacity: 1
      })
    }
    return ({
      filter: 'grayscale(100%)',
      opacity: 0.5
    })
  }

  return (
    <>
      <Box style={{ ...styles.imagesContainer }}>
        <Flag size={1.8} language={LanguageEnum.spanish} style={selectedLanguage(LanguageEnum.spanish)} onClick={onChange} showTranslationIcon={value === LanguageEnum.spanish && isTranslation} />
        <Flag size={1.8} language={LanguageEnum.english} style={selectedLanguage(LanguageEnum.english)} onClick={onChange} showTranslationIcon={value === LanguageEnum.english && isTranslation} />
      </Box >
    </>
  )
}

export function LanguageBar({ onChange = (language) => { }, value = LanguageEnum.spanish, isTranslation = false }) {
  const [position, setPosition] = useState('absolute')

  const changeScrolled = () => {
    if (window.scrollY > 1) {
      setPosition("fixed")
    } else {
      setPosition('absolute')
    }
  };
  window.addEventListener("scroll", changeScrolled);

  return <>
    <div style={{ ...styles.bar, position }}>
      <LanguageSelector onChange={onChange} value={value} isTranslation={isTranslation} />
    </div >
  </>
}



