import { TOP_LIMIT_PAGINATION, getVisiblePages } from "components/Table/config"
import { useState } from "react"

const usePagination = (props) => {
  const { count, rowsPerPage, onChangePage } = props
  const totalPages = Math.ceil(count / rowsPerPage)

  const [currentPage, setCurrentPage] = useState(1)
  const [visiblePages, setVisiblesPages] = useState(getVisiblePages(totalPages))


  function handleChangePage(newPage) {
    setCurrentPage(newPage)
    onChangePage(newPage)

    if (totalPages <= TOP_LIMIT_PAGINATION) return

    if (newPage <= 2) return setVisiblesPages(getVisiblePages(totalPages))

    if (newPage == totalPages) return setVisiblesPages([3, 2, 1].map(n => totalPages - n))

    if (newPage >= 3 && newPage + 1 != totalPages) return setVisiblesPages([newPage - 1, newPage, newPage + 1])
  }

  return {
    handleChangePage,
    currentPage,
    visiblePages,
    totalPages,
  }
}

export default usePagination
