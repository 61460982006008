export const initialReportCategoryFormData = [
    {
        id: "name",
        type: "INP",
        placeholder: "",
        label: "Nombre(Es)",
        validations: {
            required: 'Campo requerido',
        }
    },
    {
        id: "name_en",
        type: "INP",
        placeholder: "",
        label: "Nombre(En)",
        validations: {
            required: 'Campo requerido',
        }
    },
    {
        id: "description",
        type: "INP",
        placeholder: "",
        label: "Descripción",
    }
]

export const HEADERS = [
    { label: "Nombre", fieldName: 'name', sortable: true },
    { label: "Descripción", fieldName: 'description', sortable: true },
    { label: "Diario", fieldName: 'isDailyPublished', sortable: true },
    { label: "Matutino", fieldName: 'morningFeatured', sortable: true },
    { label: "Vespertino", fieldName: 'eveningFeatured', sortable: true },
    "",
    ""]
export const TITLE = "Categorías de reporte"

export const FILTER_CONFIG = [
    { label: 'Nombre', type: 'string', visible: true, fieldName: 'name' },
    { label: 'Descripción', type: 'string', visible: true, fieldName: 'description' },
    {
        label: 'Diario', type: 'select', visible: true, fieldName: 'isDailyPublished',
        options: [{
            value: true,
            label: 'Sí'
        },
        {
            value: false,
            label: 'No'
        }
        ]
    },
    {
        label: 'Matutino', type: 'select', visible: true, fieldName: 'morningFeatured',
        options: [{
            value: true,
            label: 'Sí'
        },
        {
            value: false,
            label: 'No'
        }
        ]
    },
    {
        label: 'Vespertino', type: 'select', visible: true, fieldName: 'eveningFeatured',
        options: [{
            value: true,
            label: 'Sí'
        },
        {
            value: false,
            label: 'No'
        }
        ]
    },
]
