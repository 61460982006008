import CRUDService from '../../../../../services/CRUDService'
/**
 * Permite obtener el objeto de opciones con datos dinámicos. Toma como valor el `id`. El valor mostrado será el campo que se especifica en `labelField`
 * @param resource Nombre del recurso.
 * @param labelField Nombre del campo que mostrará en select
 * @returns {Array<{value:any,label:string}>}
 */
export function defaultOptionsGetter(resource = "", labelField = "") {
    return async function () {
        const service = new CRUDService(resource)
        const elements = (await service.find({ fields: ['id', labelField], order: `${labelField} ASC` })).data
        return elements.map((e) => { return { value: e.id, label: e[labelField] } })
    }
}
