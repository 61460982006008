import { ProgressBar } from "components/ProgressBar/ProgressBar";
import { AppContext } from "context";
import useAuth from 'hooks/useAuth';
import AuthLayout from "layouts/Auth";
import { useContext, useEffect, useState } from "react";
import { Switch } from "react-router-dom";
import { Redirect, Route } from "react-router-dom/cjs/react-router-dom.min";
import AdminLayout from "../layouts/Admin.js";

export const Routing = () => {
  const [loading, setLoading] = useState(true)
  const { currentUser } = useContext(AppContext)
  const { refreshUser } = useAuth()

  const checkSession = async () => {
    if (!currentUser) {
      try {
        setLoading(true)
        await refreshUser()
      } catch (err) {
        console.error(err)
      } finally {
        setTimeout(() => {
          setLoading(false)
        }, [1000])
      }
    }
  }

  useEffect(() => {
    checkSession()
  }, [])

  if (loading) {
    return (
      <ProgressBar loading={loading} />
    )
  }

  return (
    <Switch>
      {
        currentUser
          ? <Route path={`/admin`} component={AdminLayout} />
          : <Route path={`/admin/auth`} component={AuthLayout} />
      }
      <Redirect from="*" to={{ pathname: "/admin/users" }} />
    </Switch>
  )
}

export default Routing
