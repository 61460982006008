import { useEffect } from "react";
import './dropzone.css';
const { useDropzone } = require('react-dropzone');

function Dropzone({ onFileRead, accept, error }) {
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({ accept });

  useEffect(() => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        if (!!e.target && typeof e.target.result === 'string') {
          onFileRead(
            {
              base64: e.target.result,
              file: file
            }
          );
        }
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, [acceptedFiles])

  return (
    <div style={{ cursor: 'pointer' }}>
      <div {...getRootProps({ className: "dropzone" })} style={{ borderColor: error ? 'red' : 'rgb(55, 211, 133)' }}>
        <input {...getInputProps()} />
        <p>Arrastre un archivo o haga click para agregar archivo</p>
      </div>
      <aside>
      </aside>
    </div>
  );
}

export default Dropzone;
