import { useRef, useState } from "react"
import ReportService from "../services/ReportService"
import ReportViewService from "../services/ReportViewService"
import { getOrderParam } from "../utils/filter"

const useReports = () => {
    const [data, setData] = useState([])
    const whereFilterRef = useRef()
    const orderRef = useRef()

    async function getReports(page) {
        const { list, count } = await ReportViewService.getList(page, {
            where: { ...whereFilterRef.current },
            order: orderRef.current
        })
        setData({
            reports: list,
            count
        })
    }

    function changeFilter(where) {
        whereFilterRef.current = where;
        getReports()
    }

    function changeOrder(orderArray) {
        orderRef.current = getOrderParam(orderArray)
        getReports()
    }

    async function createReport(data) {
        await ReportService.create(data)
        getReports()
    }

    async function updateReport(data) {
        const report = { ...data }

        delete report.id
        delete report.createdAt
        delete report.updatedAt

        await ReportService.updateFields(data.id, report)
        getReports()
    }

    return {
        data,
        getReports,
        createReport,
        updateReport,
        changeFilter,
        changeOrder
    }
}

export default useReports
