import { useEffect } from "react"

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 200
  },
  separator: {
    backgroundColor: 'lightgray',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '70%',
    color: 'lightgray',
    height: 5
  }
}

export default function Separator(props) {
  const { blockDataObject, blockKey, setIsUsing } = props

  useEffect(() => {
    setIsUsing(true)
  }, [])

  return (
    <div style={styles.container}>
      <div style={styles.separator}></div>
    </div>
  )
}
