import { ContentState, EditorState, Modifier, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React from "react";

/**
 *Esta funcion se encarga de que el texto sea pegado en el editor conservando los estilos desde donde se copian
 * @param {React.Dispatch<React.SetStateAction<EditorState>>} setEditorState
 * @returns
 */
export const handlePastedText = setEditorState => {
  return (text, html, editorState) => {
    const regex = /<!--StartFragment-->([\s\S]*?)<!--EndFragment-->/;
    const match = html?.match(regex);
    if (match && match[1]) {
      let cleanHtml = wordStylesToCSS(match[1])
      cleanHtml = cleanHtml.replace(/\n/g, ' ').replace(/\s\s+/g, ' ');
      const blocksFromHtml = htmlToDraft(cleanHtml);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
      let newState = Modifier.replaceWithFragment(editorState.getCurrentContent(), editorState.getSelection(), contentState.getBlockMap())
      setEditorState(EditorState.push(editorState, newState, 'insert-fragment'))
      return true
    }
    return false
  }
}

/**
 *Esta funcion se encarga de convertir estilos de word a css
*/
function wordStylesToCSS(html) {
  let parser = new DOMParser();
  let doc = parser.parseFromString(html, 'text/html');
  // Convertir elementos <b> a estilos CSS
  doc.body.querySelectorAll('b').forEach(el => {
    let styles = el.getAttribute('style') || '';
    el.outerHTML = `<span style="font-weight: bold;${styles}">${el.innerHTML}</span>`;
  });
  // Convertir elementos <i> a estilos CSS
  doc.body.querySelectorAll('i').forEach(el => {
    let styles = el.getAttribute('style') || '';
    el.outerHTML = `<span style="font-style: italic;${styles}">${el.innerHTML}</span>`;
  });
  // Convertir elementos <u> a estilos CSS
  doc.body.querySelectorAll('u').forEach(el => {
    let styles = el.getAttribute('style') || '';
    el.outerHTML = `<span style="text-decoration: underline;${styles}">${el.innerHTML}</span>`;
  });
  doc.body.querySelectorAll('*').forEach(el => {
    if (el.getAttribute('style')) {
      let styles = el.getAttribute('style');
      styles = styles.split(';').filter(style => !style.trim().startsWith('mso-')).join(';');
      // Actualizar los estilos inline del elemento
      el.setAttribute('style', styles);
    }
  });
  return doc.body.innerHTML;
}


/**
 * Modifica el cubo del color `inherit` del colorpicker.
 * @param {React.MutableRefObject<any>} editorRef
 * @returns
 */
export const changeColorPickerCube = (editorRef) => {
  return () => {
    if (!editorRef?.current) return
    //Se puso un timeout porque la funcion se usa en el evento click y se ejecuta antes de montar el modal de selección.
    setTimeout(() => {
      const collection = editorRef.current.querySelectorAll(".rdw-colorpicker-cube");
      for (let i = 0; i < collection.length; i++) {
        let element = collection[i]
        if (element.style.backgroundColor === "inherit") {
          element.style.color = 'black'
          element.style.textAlign = "center"
          element.innerHTML = 'X'
        }
      }
    }, 50)
  }
}


const getRawContent = (editorState) => {
  let rawContent
  if (editorState) {
    rawContent = convertToRaw(editorState.getCurrentContent())
  }
  return rawContent
}

/**
 * Devuelve el contenido del editor en html
 * @param {EditorState} editorState
 * @returns
 */
export const getHtml = (editorState) => {
  const rawContent = getRawContent(editorState)
  if (rawContent) return draftToHtml(rawContent)
}


/**
 * Devuelve el objeto para el manejo del display de la barra de herramientas
 */
export const getToolbarDisplay = (editorRef) => {
  return {
    /**Retoma la posición inicial*/
    home: () => {
      if (!editorRef?.current) return
      var toolbarElement = editorRef.current.querySelector('.toolbarClassName')
      toolbarElement.style.position = 'relative'
      toolbarElement.style.display = 'flex'
    },
    /**Posiciona la barra como fija para el seguimiento en el scroll*/
    followMe: () => {
      if (!editorRef?.current) return
      var toolbarElement = editorRef.current.querySelector('.toolbarClassName')
      toolbarElement.style.display = 'flex'
      toolbarElement.style.position = 'fixed'
    },
    /**Oculta la barra*/
    none: () => {
      if (!editorRef?.current) return
      var toolbarElement = editorRef.current.querySelector('.toolbarClassName')
      toolbarElement.style.display = 'none'
      toolbarElement.style.position = 'relative'
    },
  }
}

export const getContentStateFromDataObject = (blockKey, blockDataObject) => {
  const html = blockDataObject[blockKey]?.data
  if (html) {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    return ContentState.createFromBlockArray(contentBlocks, entityMap);
  }
  return null
}
