import { DeleteIcon } from "@chakra-ui/icons";
import {
    Box, Button, Divider, Flex, Icon, Input,
    Switch,
    Text, Textarea, Tooltip, useToast
} from "@chakra-ui/react";
import { Dialog } from "components/Modal/Modal";
import { useContext, useEffect, useRef, useState } from "react";
import { BiSave } from "react-icons/bi";
import { FaRegEye, FaRegPaperPlane } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { Prompt, useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { cleanObject } from "utils/object";
import AlertDialogComponent from "../../components/Dialog";
import { FileUploader } from "../../components/FilerUploader/FileUploader";
import { LanguageBar } from "../../components/LanguageSelector/LanguageSelector";
import Actions from "../../components/ReportCreation/Actions";
import BlockContainer from "../../components/ReportCreation/BlockContainer";
import { CategorySelect } from "../../components/ReportCreation/CategorySelect";
import { FormLabelWithError } from "../../components/ReportCreation/FormLabelWithError";
import '../../components/ReportCreation/ReportCreation.css';
import TagInput from "../../components/ReportCreation/TagInput";
import { ChartEditor, ImageEditor, VideoEditor } from "../../components/ReportCreation/editors/FileEditors";
import Separator from "../../components/ReportCreation/editors/Separator";
import TextEditor from "../../components/ReportCreation/editors/TextEditor";
import { AppContext } from "../../context";
import { usePromise } from "../../hooks/usePromise";
import { useReportNavigation } from "../../hooks/useReportNavigation";
import ReportCategoryService from "../../services/ReportCategoryService";
import ReportService from "../../services/ReportService";
import { fileUploadErrorHandler } from "../../utils/errorHandlers";
import { getFileUrl } from "../../utils/file";
import { useBlockManager } from "./Hooks/useBlockManager";
import { useLanguageHandler } from "./Hooks/useLanguageHandler";
import { useReportValidation } from "./Hooks/useReportValidation";


const styles = {
    inputContainer: { display: 'flex', flexDirection: 'column', alignItems: 'start' },
    baseLine: { alignSelf: 'baseline' },
    marginB: { marginBottom: 15 },
    flexContainer: { flexDirection: 'column', width: '70%', height: 'auto' },
    imageContainer: { marginBottom: 20, width: '100%', alignItems: 'center', flexDirection: 'column', height: 'auto' },
    image: { maxHeight: 200 },
    categoriesContainer: { width: '50%', alignSelf: 'baseline' },
    actionContainer: { flexDirection: 'column', marginRight: 30, display: 'flex', alignItems: 'end', position: 'fixed', top: '15%', right: 20 },
    sendButton: { marginTop: 30, borderRadius: 30, width: 40, boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)' },
    blockContainer: { width: '100%' },
    errorMessage: { color: 'red', fontSize: 11, fontWeight: 'normal', marginLeft: 5 }
}

function ReportCreation() {
    const { id, language } = useParams();
    const [categories, setCategories] = useState([])
    const { addBlock, deleteBlock, moveBlock, generateBlocks, blockDataObjectRef, Blocks } = useBlockManager()
    const { formErrors, removeError, validateAndSetErrors } = useReportValidation()
    const { selectedLanguage, setSelectedLanguage, onChangeLanguage } = useLanguageHandler()
    const [reportCategoryId, setReportCategoryId] = useState(null)
    const [reportInfo, setReportInfo] = useState({
        title: '',
        description: '',
        createdAt: undefined,
        published: undefined,
        createdById: undefined,
    })
    const [show, setShow] = useState(false)
    const [isFeatured, setIsFeatured] = useState(false)
    const [cover, setCover] = useState()
    const [audio, setAudio] = useState()
    const [tags, setTags] = useState([])
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [openPublishModal, setOpenPublishModal] = useState(false)
    const [currentAction, setCurrentAction] = useState('')
    const [isTranslation, setIsTranslation] = useState(false)
    const { currentUser, loading, setLoading } = useContext(AppContext)
    const [isPublishing, setIsPublishing] = useState(false)
    const toast = useToast()
    const history = useHistory()
    const refView = useRef([])
    const newIndex = useRef(undefined)
    const firstRenderRef = useRef(true)
    const handlePromise = usePromise()
    const value = localStorage.getItem('accessToken')
    const reportNavigation = useReportNavigation()
    const isCreation = Boolean(useRouteMatch("/admin/reports/create"))

    const toggleDeleteModal = () => {
        setOpenDeleteModal(!openDeleteModal)
    }

    const togglePublishModal = () => {
        setOpenPublishModal(!openPublishModal)
    }

    const bringCategories = async () => {
        const res = await ReportCategoryService.find();
        setCategories(res.data)
    }

    /**Carga el reporte desde el parametro de ruta */
    const loadReport = () => handlePromise(async () => {
        const resTags = await ReportService.bringTags(id)
        const resReport = await ReportService.findByLanguage(id, language)
        const report = resReport.data
        const _tags = resTags.data
        setReportInfo({
            title: report.title,
            description: report.description,
            createdAt: report.createdAt,
            published: report.published,
            createdById: report.createdById,
            isTranslation: report.isTranslation,
            language: report.language
        })
        setIsFeatured(report.featured)
        setReportCategoryId(report.reportCategoryId)
        setCover(report.cover)
        setAudio(report.audio)
        setTags(_tags.map(tag => ({ value: tag.id, label: tag.name })))
        generateBlocks(report.body)
        setSelectedLanguage(report.language)
        setIsTranslation(!!report.isTranslation)
    })

    const handleChange = (e) => {
        setReportInfo({
            ...reportInfo,
            [e.target.name]: e.target.value
        })
    }

    const deleteReport = async () => {
        setIsPublishing(true)
        setLoading(true)
        try {
            if (id) {
                if (isTranslation) {
                    await ReportService.deleteTranslation(id, language)
                    await reportNavigation.goToEdition(id)
                } else {
                    await ReportService.destroy(id)
                    reportNavigation.goToCreation()
                }
            }
            window.location.reload()
        }
        finally {
            firstRenderRef.current = true
            setTimeout(() => {
                setLoading(false)
                setOpenDeleteModal(false)
                setIsPublishing(false)
            }, 1000);
        }
    }

    /**Devuelve el reporte a partir de los estados del componente */
    const getReportFromStates = () => {
        //Ordena los datos de bloque según indice
        const blocksData = Object.values(blockDataObjectRef.current)
        blocksData.sort((a, b) => {
            return a.index - b.index
        })
        //El transforma el estado tags al array de tagIds que requiere la subida del reporte
        let tagIds = tags.map((t) => t.value)
        const report =
        {
            body: blocksData,
            tagIds: tagIds,
            reportCategoryId: reportCategoryId ? Number(reportCategoryId) : undefined,
            cover: cover,
            title: reportInfo.title,
            description: reportInfo.description,
            createdAt: reportInfo.createdAt ? reportInfo.createdAt : undefined,
            published: reportInfo.published,
            createdById: reportInfo.createdById,
            featured: isFeatured,
            id: id ? Number(id) : undefined,
            audio: audio,
            //isTranslation: reportInfo.isTranslation,
            language: reportInfo.language
        }
        //Elimina propiedades null del objeto. Evita que rompa la request
        return cleanObject(report)
    }

    /**Guarda el reporte. Sobreescribe existente o crea nuevo */
    const saveReport = async ({ publish = false, autosave = false } = {}) => {
        await handlePromise(
            async () => {
                setIsPublishing(true)
                const report = getReportFromStates()
                const canBePublished = validateAndSetErrors(report, true)
                //Si es el primer guardado y se intenta publicar se frena la publicación.
                if (publish && isCreation && !canBePublished) return toast({ title: 'El informe no cumple con los requisitos para ser publicado. Puedes guardarlo para editarlo más tarde.', status: 'error' })
                //Si el reporte que se modificó estaba publicado pero se alteró de tal
                //forma que ya no reune las condiciones de publicación. No se permite el guardado
                if (report.published && !canBePublished) return toast({ title: 'El informe modificado ya no cumple con las condiciones para estar publicado. Corrige los campos requeridos.', status: 'error' })
                //El guardado de reportes no publicados no tiene restricciones
                let res = null
                if (isCreation) {
                    res = await ReportService.create({ ...report, createdById: currentUser.id }, selectedLanguage)
                } else {
                    res = await ReportService.update(report.id, report, selectedLanguage)
                }
                const savedReport = res.data
                if (!publish) {
                    //En caso de primer guardado sin publicación se navega a la edición
                    if (isCreation) return reportNavigation.goToEdition(savedReport.id, selectedLanguage)
                    //Si no se va a publicar el guardado finaliza acá
                    setIsPublishing(false)
                    if (!autosave) { return toast({ title: 'Guardado', duration: 1000, status: 'success' }) }
                    return
                }
                //Si el reporte se va a publicar debe reunir las condiciones
                if (!canBePublished) return toast({ title: 'El informe no cumple con los requisitos para ser publicado.', status: 'error' })
                //Si es válido se publica
                await ReportService.publish(res.data.id)
                toast({ title: 'Reporte publicado', status: 'success', })
                history.push('/admin/reports')
                setIsPublishing(false)
            },
        )
    }

    /**
     * Maneja acciones de bloque
     * @param {'addTextEditor'|'addSeparator'|'delete'|'moveUp'|'moveDown'|'image'|'video'|'chart'} action
     * @param {'number'} index (opcional)
     */
    const handleActions = (action, index) => {
        const addIndex = index !== undefined ? index + 1 : undefined
        newIndex.current = index !== undefined ? String(index + 1) : undefined
        setCurrentAction(action)
        switch (action) {
            case 'addTextEditor':
                addBlock({ Component: TextEditor, name: 'Bloque de texto', type: 'text' }, addIndex)
                break;
            case 'addSeparator':
                addBlock({ Component: Separator, name: 'Bloque Separador', type: 'separator' }, addIndex)
                break;
            case 'delete':
                deleteBlock(index)
                break;
            case 'moveUp':
                if (index !== undefined) moveBlock(index, { action: 'up' })
                break;
            case 'moveDown':
                if (index !== undefined) moveBlock(index, { action: "down" })
                break;
            case 'image':
                addBlock({ Component: ImageEditor, name: 'Bloque de imagen', type: 'image' }, addIndex)
                break;
            case 'video':
                addBlock({ Component: VideoEditor, name: 'Bloque de video', type: 'video' }, addIndex)
                break;
            case 'chart':
                addBlock({ Component: ChartEditor, name: 'Bloque de gráfico', type: 'chart' }, addIndex)
                break;
            default:
                console.error("Unhandled action \u{1F480}")
                break;
        }
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            saveReport({ autosave: true });
        }, 60000);

        return () => {
            clearInterval(intervalId);
        };
    }, [saveReport]);

    // useEffect(() => {
    //     const handleBeforeUnload = (event) => {
    //         event.returnValue = '¿Estás seguro de que quieres salir de esta página sin guardar?';
    //     };

    //     window.addEventListener('beforeunload', handleBeforeUnload);

    //     return () => {
    //         window.removeEventListener('beforeunload', handleBeforeUnload);
    //     };
    // }, []); sin esto ahora puede relodear sin q te avise pero con el autosave no deberia importar

    const handleCloseDeleteModal = async (action) => {
        if (action === 'accept') {
            await deleteReport()
        }
        toggleDeleteModal()
    }

    const handleClosePublishModal = async (action) => {
        if (action === 'accept') {
            await saveReport({ publish: true })
        }
        setOpenPublishModal(false)
    }

    const openView = () => {
        setShow(true)
    }

    useEffect(() => {
        bringCategories()
    }, [])

    useEffect(() => {
        if (id) {
            loadReport()
        }
    }, [id])

    useEffect(() => {
        const contenedor = document.getElementById('container')
        const containerPosition = contenedor.getBoundingClientRect()
        if (Blocks.length > 0 && refView.current.length !== 0 && currentAction !== 'delete') {
            const positionIndex = newIndex.current ? newIndex.current : refView.current.length - 1
            const blockPosition = refView.current[Number(positionIndex)].getBoundingClientRect()
            const offsetTop = blockPosition.y - containerPosition.y + 200
            window.scrollTo(blockPosition.x, offsetTop)
        }
    }, [Blocks.length])

    return (
        <>
            <AlertDialogComponent
                title={`¿Eliminar ${isTranslation ? 'Traducción' : 'Informe'}?`}
                body={`¿Estás seguro de que deseas eliminar ${isTranslation ? 'esta traducción' : 'este informe'}? Esta acción es irreversible y perderás todos los datos permanentemente.`}
                isOpen={openDeleteModal}
                onClick={handleCloseDeleteModal}
                loading={loading}
                type='warning'
            />
            <AlertDialogComponent
                title='¿Desea publicar el reporte?'
                body='Estás a punto de publicar el informe. ¿Deseas continuar?'
                isOpen={openPublishModal}
                onClick={handleClosePublishModal}
                loading={loading}
            />
            <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
                <div style={styles.inputContainer}>
                    <LanguageBar onChange={onChangeLanguage} value={selectedLanguage} isTranslation={isTranslation} />
                    <Flex style={styles.flexContainer}>
                        {/* TITULO */}
                        <FormLabelWithError label="Título*" error={formErrors.title} />
                        <Input mb={5} onChange={handleChange} onFocus={() => removeError('title')} onBlur={() => validateAndSetErrors({ title: reportInfo.title })} value={reportInfo.title} name="title" width={'100%'} size='lg' height={'35px'} style={{ borderColor: formErrors.title ? 'red' : 'inherit' }} />
                        {/* DESCRIPCION */}
                        <Text as={'b'} mb={1} fontSize={'lg'} style={styles.baseLine}>Descripción* <span style={styles.errorMessage}>{formErrors.description ? formErrors.description : null}</span></Text>
                        <Textarea mb={5} onChange={handleChange} onFocus={() => removeError('description')} onBlur={() => validateAndSetErrors({ description: reportInfo.description })} value={reportInfo.description} fontSize={17} maxHeight={9} name="description" width={'100%'} size='sm' style={{ borderColor: formErrors.description ? 'red' : 'inherit' }} />
                        {/* AUDIO */}
                        <Text as={'b'} mb={1} fontSize={'lg'} style={styles.baseLine}>
                            Audio
                        </Text>
                        <FileUploader
                            previewSrc={audio}
                            onUpload={(file) => setAudio(file)}
                            onDelete={() => setAudio()}
                            hasError={''}
                            onError={() => { }}
                            type={'audio'}
                        />
                        {/* PORTADA */}
                        <FormLabelWithError label='Portada*' error={formErrors.cover} />
                        <Text color={"#cc7000"} fontSize={"sm"}>La imagen debe tener una resolución de 1920x1080</Text>
                        <FileUploader
                            previewSrc={getFileUrl(cover)}
                            onUpload={(file) => setCover(file.filename)}
                            onDelete={() => setCover()}
                            hasError={formErrors.cover}
                            onError={fileUploadErrorHandler(toast)}
                        />
                        {/* CATEGORY */}
                        <Box width={'80%'} mb={5} alignSelf={"baseline"}>
                            <FormLabelWithError label='Categorias*' error={formErrors.reportCategoryId} />
                            <CategorySelect
                                categories={categories}
                                value={reportCategoryId}
                                onChange={(e) => setReportCategoryId(e.target.value)}
                                onBlur={(e) => validateAndSetErrors({ reportCategoryId: e.target.value })}
                                onFocus={() => removeError('reportCategoryId')}
                                error={formErrors.reportCategoryId}
                            />
                        </Box>
                        {/* TAGS */}
                        <Flex mb={5} flexDirection={'column'} alignSelf={"baseline"}>
                            <FormLabelWithError label='Tags*' error={formErrors.tagIds} />
                            <TagInput
                                tags={tags} setTags={setTags}
                                removeError={removeError}
                                handleErrors={validateAndSetErrors}
                                error={!!formErrors.tagIds}
                            />
                        </Flex>
                        {/* FEATURED */}
                        <Flex alignItems={'center'}>
                            <FormLabelWithError label='Destacar noticia' />
                            <Switch ml={2} onChange={() => setIsFeatured(!isFeatured)} size='md' isChecked={isFeatured} />
                        </Flex>
                        <Divider mt={10} mb={7} height={0.5} borderWidth={0} backgroundColor={'grey'} />
                        {/* CUERPO */}
                        <FormLabelWithError label='Cuerpo*' error={formErrors.body} />
                    </Flex >
                    <div style={{ ...styles.actionContainer }}>
                        <Actions onClick={handleActions} direction={'column'}></Actions>
                        <Tooltip label='Guardar cambios'>
                            <Button onClick={() => saveReport()} colorScheme="green" style={{ borderRadius: 30, width: 40, boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)', marginTop: '25px' }}>
                                <Icon boxSize={5} as={BiSave} />
                            </Button>
                        </Tooltip>
                        <Button onClick={(() => openView())} colorScheme="green" style={{ borderRadius: 30, width: 40, boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)', marginTop: '25px' }} >
                            <Icon boxSize={5} as={FaRegEye} />
                        </Button>
                    </div>
                    <div id="container" style={styles.blockContainer}>
                        {
                            Blocks.map((Block, index) => <BlockContainer reference={refView.current} id={index} Block={Block} key={Block.key} blockDataObject={blockDataObjectRef.current} index={index} handleActions={handleActions}></BlockContainer>)
                        }
                    </div>
                    {/* BOTONES */}
                    <Flex position={'fixed'} bottom={'35px'} right={'95px'} mt={5} flexDirection={'row'} >
                        <Tooltip label='Eliminar'>
                            <Button ml={2} onClick={() => toggleDeleteModal()} colorScheme="red"><Icon boxSize={5} as={DeleteIcon} /><span className="buttonText">{`Eliminar ${isTranslation ? 'traducción' : 'reporte'}`}</span></Button>
                        </Tooltip>
                        {
                            Blocks.length !== 0 ?
                                <Tooltip label={reportInfo.published ? 'Este reporte ya está publicado' : 'Publicar reporte'}>
                                    <div>
                                        <Button disabled={reportInfo.published} ml={2} onClick={() => togglePublishModal()} colorScheme="green" >  <Icon boxSize={5} as={FaRegPaperPlane} /><span className="buttonText">Publicar reporte</span></Button>
                                    </div>
                                </Tooltip>
                                :
                                null
                        }
                    </Flex>
                    {
                        show &&
                        <Dialog onClose={() => setShow(false)}>
                            < iframe
                                src={`https://app.pxqconsultora.com/?token=${value}&redirectUrl=reports/${id}&language=${language}`}
                                title="description"
                                style={{ display: 'flex', height: '100vh', width: '95vw', alignSelf: 'center', justifyContent: 'center' }}
                            ></iframe>
                        </Dialog>
                    }
                    {
                        !isPublishing &&
                        <Prompt
                            when={true}
                            message="¿Estás seguro de que quieres salir de esta página sin guardar?"
                        />

                    }
                </div >
            </Flex >
        </>
    )
}

export default ReportCreation
